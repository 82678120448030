import { Dispatch, PropsWithChildren, createContext, useContext, useEffect, useRef } from "react";

import { VESSEL_STORAGE_KEY } from "src/config/constants";

import useAppState from "../hooks/useAppState";
import { defaultAppState } from "../state/AppState";
import AppState, { AppStateAction } from "../state/models";

export const AppStateContext = createContext<[state: AppState, action: Dispatch<AppStateAction>]>([
  defaultAppState,
  () => {
    return;
  },
]);

export const useAppStateContext = () => useContext(AppStateContext);

export const AppStateLifecycle: React.FC = () => {
  const [appState, dispatch] = useAppStateContext();
  const firstUpdate = useRef<boolean>(true);

  useEffect(() => {
    if (firstUpdate.current === true) {
      const data = localStorage.getItem(VESSEL_STORAGE_KEY);
      // we remove vesselToken property before dispatching action
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { vesselToken = null, ...value } = { ...JSON.parse(data as string) };
      if (data) {
        dispatch({
          type: "setAppState",
          value,
        });
      }
      firstUpdate.current = false;
      return;
    }
    // NOTE: For now not full state is stored
    // previously this logic was invalid and updating full store should be better tested
    const { orderType, port, deliveryDate, dutyFreeDeclaration, appArea, orderName } = appState;
    localStorage.setItem(
      VESSEL_STORAGE_KEY,
      JSON.stringify({
        orderType,
        deliveryDate,
        dutyFreeDeclaration,
        port,
        appArea,
        orderName,
      })
    );
  }, [appState, dispatch]);

  return null;
};

export const AppStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [appState, dispatch] = useAppState();

  return (
    <AppStateContext.Provider value={[appState, dispatch]}>{children}</AppStateContext.Provider>
  );
};

export default AppStateContext;
