import { useCallback, useMemo } from "react";

import { Category } from "@web/models";
import { useWindowDimensions } from "@web/utils";

import { CategorizedProductsList } from "src/components/CategorizedProductsList";
import { ChunkedItemsList } from "src/components/ChunkedItemsList";
import { StocktakeFormCatalogProductTile } from "src/components/product-tile/stocktake/StocktakeFormCatalogProductTile";
import { LocalStocktakeReportItemForm } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

type Props = {
  categories: Category[];
  activeCategoryId: string | undefined;
  items: LocalStocktakeReportItemForm[];
};

const XL_BREAKPOINT = 1280;
// Remember to adjust `ITEMS_GAP` when changing the product tiles spacing.
const ITEMS_GAP = 8;
const CHUNK_SIZE = 10;

export const StocktakeItemsList = ({ categories, items, activeCategoryId }: Props) => {
  const nonEmptyCategories = useMemo(
    () => LocalStocktakeService.getNonEmptyCategories(categories),
    [categories]
  );

  const chunkedItemsGroupedByCategoryId = useMemo(
    () => LocalStocktakeService.getChunkedItemsGroupedByCategoryId(items, CHUNK_SIZE),
    [items]
  );

  const { width } = useWindowDimensions();
  // Remember to adjust `estimatedItemHeight` when changing the product tiles size.
  // `ChunkedItemsList` does not support window resizing, so the window needs to be refreshed to apply the new values after resizing.
  const estimatedItemHeight = width < XL_BREAKPOINT ? 160 : 82;

  // ---------------------------------------------------------------------------

  const renderChunkItem = useCallback(
    (item: LocalStocktakeReportItemForm & { index: number }) => (
      <StocktakeFormCatalogProductTile
        key={item.id}
        product={item}
        lineNumber={item.lineNumber}
        indexInCollection={item.index}
      />
    ),
    []
  );

  const renderCategoryItems = useCallback(
    (categoryId: string) => (
      <ChunkedItemsList
        className="flex flex-col gap-2"
        chunkWrapperClassName="flex flex-col gap-2"
        chunkedItems={chunkedItemsGroupedByCategoryId[categoryId] || []}
        estimatedItemHeightInPx={estimatedItemHeight}
        itemsGapInPx={ITEMS_GAP}
        renderChunkItem={renderChunkItem}
      />
    ),
    [chunkedItemsGroupedByCategoryId, estimatedItemHeight, renderChunkItem]
  );

  // ---------------------------------------------------------------------------

  // Postpone rendering until width is set to avoid costly rerenders
  if (!width) {
    return null;
  }

  return (
    <CategorizedProductsList
      categories={nonEmptyCategories}
      activeCategoryId={activeCategoryId}
      renderCategoryItems={renderCategoryItems}
    />
  );
};
