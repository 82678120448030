import { memo } from "react";

import { Badge, Paragraph } from "@web/ui";

import {
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { useItemFormDetails } from "../hooks";

type Props = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const StocktakeProductTileRobDetails = memo(
  ({
    initialQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    salesEntityQuantity,
    currencyCode,
    quantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { modifiedEntityQuantity, modifiedMeasurementUnit } = useItemFormDetails({
      initialQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      salesEntityQuantity,
      currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    return (
      <>
        <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
          ROB:
        </Paragraph>
        <Badge
          size="m"
          color="success"
          contrast="low"
          text={`${LocalStocktakeService.formatEntityQuantity(modifiedEntityQuantity)}${
            modifiedMeasurementUnit ? ` ${modifiedMeasurementUnit}` : ""
          }`}
        />
      </>
    );
  }
);
StocktakeProductTileRobDetails.displayName = "StocktakeProductTileRobDetails";
