import { differenceInMinutes, format, isAfter } from "date-fns";

import { Paragraph, ParagraphProps } from "@web/ui";

const displayLastUpdatedInformation = (lastUpdated: Date) => {
  const numberOfMinutesAgo = differenceInMinutes(Date.now(), lastUpdated);

  if (numberOfMinutesAgo <= 1) {
    return "Saved just now";
  }

  if (numberOfMinutesAgo < 60) {
    return `Updated ${numberOfMinutesAgo} minutes ago`;
  }

  return `Last updated: ${format(lastUpdated, "LLL d, y")}`;
};

interface Props {
  draftLastUpdated: string;
  isSavingDraft: boolean;
  basketLastUpdated: number | undefined;
  paragraphSize?: "100" | "200" | "300" | "400";
  textColor?: ParagraphProps["color"];
}

export const OrderDraftSavedInfo = ({
  draftLastUpdated,
  isSavingDraft,
  basketLastUpdated,
  paragraphSize,
  textColor = "text-textIcon-blackSecondary",
}: Props) => {
  const isPending =
    isSavingDraft || (basketLastUpdated && isAfter(basketLastUpdated, new Date(draftLastUpdated)));

  return (
    <Paragraph
      size={paragraphSize || "300"}
      color={textColor}
      className="flex items-center"
      data-testid="draftSavedInfo"
    >
      {isPending ? "Saving..." : displayLastUpdatedInformation(new Date(draftLastUpdated))}
    </Paragraph>
  );
};
