import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteBasketFormAnswer } from "src/typegens";

export const LiteBasketFormAnswerSchema = z.object({
  number: z.number(),
  question: z.string(),
  answer: z.string().optional(),
});

type LocalLiteBasketFormAnswer = z.infer<typeof LiteBasketFormAnswerSchema>;

assertTypeEquality<LocalLiteBasketFormAnswer, LiteBasketFormAnswer, LocalLiteBasketFormAnswer>();
