import { TrashIcon } from "@heroicons/react/outline";
import { memo, useCallback } from "react";
import { UseFieldArrayRemove } from "react-hook-form";

import { UnitOfMeasure } from "@web/models";
import { IconButton, Label } from "@web/ui";

import {
  BasketExtraItemMeasurementUnitFieldPath,
  BasketExtraItemNameFieldPath,
  BasketExtraItemQuantityFieldPath,
  BasketExtraItemRefProductCodeFieldPath,
  BasketExtraItemRefUrlFieldPath,
  LocalBasketExtraItemForm,
} from "src/models";

import { useFormFieldKeyboardEvents } from "../../hooks";
import { FormErrors } from "./FormErrors";
import { ProductNameField } from "./ProductNameField";
import { QuantityField } from "./QuantityField";
import { RefProductCodeField } from "./RefProductCodeField";
import { RefUrlField } from "./RefUrlField";
import { UnitOfMeasureField } from "./UnitOfMeasureField";

type Props = {
  product: LocalBasketExtraItemForm;
  indexInCollection: number;
  remove: UseFieldArrayRemove;
  unitOfMeasures: UnitOfMeasure[];
};

export const BasketFormExtraProductTile = memo(
  ({ product, indexInCollection, remove, unitOfMeasures }: Props) => {
    const quantityFieldPath: BasketExtraItemQuantityFieldPath = `extraItems.${indexInCollection}.quantity`;
    const measurementUnitFieldPath: BasketExtraItemMeasurementUnitFieldPath = `extraItems.${indexInCollection}.measurementUnit`;
    const nameFieldPath: BasketExtraItemNameFieldPath = `extraItems.${indexInCollection}.name`;
    const refProductCodeFieldPath: BasketExtraItemRefProductCodeFieldPath = `extraItems.${indexInCollection}.refProductCode`;
    const refUrlFieldPath: BasketExtraItemRefUrlFieldPath = `extraItems.${indexInCollection}.refUrl`;
    const itemsFieldPath = "extraItems";

    const removeItem = useCallback(() => remove(indexInCollection), [indexInCollection, remove]);

    const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLButtonElement>();

    return (
      <div>
        <div className="flex justify-between items-center">
          <div className="flex-auto flex flex-col items-center p-4 gap-4 bg-neutral_0 border border-neutral_300 rounded-xl">
            <div className="self-stretch flex justify-between items-center gap-4">
              <div className="grow basis-3/5 xl:basis-8/12">
                <ProductNameField nameFieldPath={nameFieldPath} />
              </div>
              <div className="flex-none w-[80px]">
                <QuantityField
                  initialQuantity={product.quantity}
                  quantityFieldPath={quantityFieldPath}
                />
              </div>
              <div className="grow basis-1/5 xl:basis-2/12">
                <UnitOfMeasureField
                  measurementUnitFieldPath={measurementUnitFieldPath}
                  unitOfMeasures={unitOfMeasures}
                />
              </div>
              <div className="flex-none flex gap-3">
                <div
                  className="flex-none self-stretch border-r-1 border-neutral_300"
                  role="presentation"
                ></div>
                <IconButton
                  size="large"
                  variant="secondary"
                  shape="square"
                  label="Remove Item"
                  Icon={TrashIcon}
                  onClick={removeItem}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div
              className="flex-none self-stretch border-t-1 border-neutral_300"
              role="presentation"
            ></div>
            <div className="self-stretch flex justify-between items-center gap-4">
              <div className="grow basis-4/12 lg:basis-3/12 xl:basis-2/12 flex flex-col gap-2">
                <Label size="100">
                  Ref Code <span className="text-textIcon-blackSecondary">(optional)</span>
                </Label>
                <RefProductCodeField refProductCodeFieldPath={refProductCodeFieldPath} />
              </div>
              <div className="grow basis-8/12 lg:basis-9/12 xl:basis-10/12 flex flex-col gap-2">
                <Label size="100">
                  Reference Link <span className="text-textIcon-blackSecondary">(optional)</span>
                </Label>
                <RefUrlField refUrlFieldPath={refUrlFieldPath} />
              </div>
            </div>
          </div>
        </div>
        <FormErrors indexInCollection={indexInCollection} itemsFieldPath={itemsFieldPath} />
      </div>
    );
  }
);
BasketFormExtraProductTile.displayName = "BasketFormExtraProductTile";
