import classNames from "classnames";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ErrorLabel from "@web/common/components/ErrorLabel";
import { LeadTime } from "@web/common/components/LeadTime";
import { LineItemQuantity } from "@web/common/components/LineItemQuantity";
import { TotalUnitOfMeasure } from "@web/common/components/TotalUnitOfMeasure";
import WarningLabel from "@web/common/components/WarningLabel";
import { BasketEntry } from "@web/common/models/basket";
import {
  getLineItemTotal,
  getMinimumOrderQuantity,
  getMinimumQuantityNumber,
  getSalesEntityQuantity,
} from "@web/common/utils";
import { Label, Paragraph } from "@web/ui";
import { formatMoney, imagor } from "@web/utils";

interface Props {
  lineItem: BasketEntry;
  productRoute?: string;
  setQuantity: (basketEntry: BasketEntry) => (q: number) => void;
  isRestoreEnabled?: boolean;
  initialQuantity: number;
  onLineItemClick?: () => void;
}

const ProductLink = ({
  children,
  productRoute,
  productId,
  className = "",
}: {
  children: React.ReactNode;
  productRoute?: string;
  productId?: string;
  className?: string;
}) =>
  productRoute && productId ? (
    <Link to={`${productRoute}/${productId}`} className={className}>
      {children}
    </Link>
  ) : (
    children
  );

export const LineItemUI: React.FC<Props> = memo(
  ({
    lineItem,
    productRoute,
    setQuantity,
    isRestoreEnabled = false,
    initialQuantity,
    onLineItemClick,
  }) => {
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const { t } = useTranslation();
    const { sku, quantity } = lineItem;

    const isRemoved = quantity === 0;

    const minimumOrderQuantity = getMinimumOrderQuantity(sku);
    const customerOrderQuantity = sku.about?.generalInformation?.customerOrderQuantity;
    const impaCode = sku.about?.generalInformation?.impaCode;
    const minimumQuantityNumber = getMinimumQuantityNumber(sku);

    return (
      <div
        className={`flex flex-grow justify-between content-between bg-neutral_0 rounded-lg px-4 py-2 mt-2 shadow-sm ${
          isRemoved ? "bg-neutral_200" : ""
        }`}
        data-testid="basketLineItem"
      >
        <ProductLink
          productRoute={productRoute}
          productId={sku.productId}
          className="flex flex-grow"
        >
          <div
            className={classNames(
              "flex flex-col align-top w-10",
              onLineItemClick && "cursor-pointer"
            )}
            onClick={productRoute ? undefined : onLineItemClick}
          >
            <img
              alt="product image"
              src={sku.images?.[0]?.imageUrl && imagor(sku.images?.[0]?.imageUrl)}
              className={`h-8 object-scale-down ${isRemoved ? "opacity-60" : ""}`}
            />
          </div>

          <div className="col-span-5 pl-4 flex flex-col content-between mr-auto">
            <div className="flex flex-col items-start">
              <Label
                size="200"
                color={isRemoved ? "text-textIcon-blackSecondary" : "text-textIcon-blackPrimary"}
              >
                {sku.about?.name}
              </Label>
              {isErrorMessage && (
                <ErrorLabel
                  errorMessage={t("common.cards.addToCart.minimumOrderQuantityError")}
                  minimumOrderQuantity={minimumOrderQuantity}
                  customerOrderQuantity={customerOrderQuantity}
                  measurementUnit={sku.measurementUnit}
                />
              )}
              <div className="flex justify-start items-stretch">
                {!!sku.leadTime && <LeadTime leadTime={sku.leadTime} customStyling="mr-2" />}
                {!isErrorMessage && minimumOrderQuantity && minimumQuantityNumber > 1 && (
                  <WarningLabel
                    warningMessage={t("common.views.basket.minimum")}
                    minimumOrderQuantity={minimumOrderQuantity}
                    minimumQuantityNumber={minimumQuantityNumber}
                    customerOrderQuantity={customerOrderQuantity}
                    measurementUnit={sku.measurementUnit}
                  />
                )}
              </div>
            </div>
            <div className="flex pt-2">
              {!!impaCode && (
                <>
                  <Paragraph size="300" color="text-textIcon-blackSecondary">
                    IMPA:{" "}
                  </Paragraph>
                  <Paragraph size="300" color="text-textIcon-blackSecondary" className="pl-2 pr-6">
                    {impaCode}
                  </Paragraph>
                </>
              )}
            </div>
          </div>
        </ProductLink>

        <div className="pl-4 grid grid-flow-col-dense grid-cols-3 gap-1">
          <div className="flex items-center">
            <LineItemQuantity
              quantity={quantity}
              initialQuantity={initialQuantity}
              onQuantityChange={setQuantity(lineItem)}
              setIsErrorMessage={({ err }: { err: boolean }) => setIsErrorMessage(err)}
              min={minimumQuantityNumber}
              customerOrderQuantity={customerOrderQuantity}
              shouldTrack
              isRestorationEnabled={isRestoreEnabled}
            />
          </div>
          <div className="flex flex-grow">
            <TotalUnitOfMeasure
              className="justify-center"
              lineThrough={isRemoved}
              quantityInBasket={isRemoved ? initialQuantity : quantity}
              salesEntityQuantity={getSalesEntityQuantity(sku)}
              measurementUnit={sku.measurementUnit}
              variant="positive"
            />
          </div>

          <div className="text-right flex flex-col content-between">
            <div>
              <Label size="100" className={isRemoved ? "line-through" : ""}>
                {formatMoney(getLineItemTotal(lineItem, isRemoved ? initialQuantity : quantity))}
              </Label>
            </div>
            <Paragraph size="300" color="text-textIcon-blackSecondary">{`${formatMoney(
              sku.price.costPrice
            )}/${sku.measurementUnit}`}</Paragraph>
          </div>
        </div>
      </div>
    );
  }
);

LineItemUI.displayName = "LineItemUI";
