import classnames from "classnames";
import { memo } from "react";

import { Paragraph } from "@web/ui";

import { LocalLiteStocktakeExtraItem, LocalStocktakeReportOldExtraItemForm } from "src/models";

type Props = {
  product: LocalLiteStocktakeExtraItem | LocalStocktakeReportOldExtraItemForm;
  className: string;
};

export const StocktakeExtraProductTileDetails = memo(({ product, className }: Props) => (
  <div className={classnames(className, "flex flex-col justify-center items-start")}>
    <Paragraph size="200" weight="heavy" className="mb-1">
      {product.name}
    </Paragraph>
    {!!product.articleCode && (
      <Paragraph size="400" weight="heavy" color="text-textIcon-blackSecondary">
        Article code: {product.articleCode}
      </Paragraph>
    )}
  </div>
));
StocktakeExtraProductTileDetails.displayName = "StocktakeExtraProductTileDetails";
