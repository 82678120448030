import { useEffect, useState } from "react";

import { Loading } from "@web/ui";

import { clientServiceWorkerBroadcastChannel } from "src/broadcastChannels";
import { serviceWorkerMessages } from "src/config/constants";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { keyvalStore } from "src/objectStorage";

interface Props {
  children: React.ReactNode;
}

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT as string;

export const OfflineCapabilitiesGuard = ({ children }: Props) => {
  const [isOfflineCapabilitiesFlagPersisted, setIsOfflineCapabilitiesFlagPersisted] =
    useState(false);
  const [isNetworkStatePersisted, setIsNetworkStatePersisted] = useState(false);
  const [isServiceWorkerActivated, setIsServiceWorkerActivated] = useState(false);
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { currentNetworkState, isNetworkConnectionDetected } = useNetworkDetector();
  const isDevMode = ENVIRONMENT === "development";

  const handleActivatedServiceWorkerEvent = (event: MessageEvent) => {
    if (event.data && event.data.type === serviceWorkerMessages.WAS_SERVICE_WORKER_ACTIVATED) {
      setIsServiceWorkerActivated(true);
    }
  };

  useEffect(() => {
    if (navigator.serviceWorker.controller?.state === "activated") {
      setIsServiceWorkerActivated(true);
    }
    // if SW is not in `activated` state during the initial render, then listen for this event
    clientServiceWorkerBroadcastChannel.onmessage = handleActivatedServiceWorkerEvent;

    return () => {
      clientServiceWorkerBroadcastChannel.close();
    };
  }, []);

  useEffect(() => {
    setIsOfflineCapabilitiesFlagPersisted(false);
    keyvalStore
      .put(areOfflineCapabilitiesEnabled, "areOfflineCapabilitiesEnabled")
      .then(() => {
        setIsOfflineCapabilitiesFlagPersisted(true);
      })
      .finally(() => {
        // TODO: #11671 add error handling
        setIsOfflineCapabilitiesFlagPersisted(true);
      });
  }, [areOfflineCapabilitiesEnabled]);

  useEffect(() => {
    keyvalStore
      .put(currentNetworkState, "networkState")
      .then(() => {
        setIsNetworkStatePersisted(true);
      })
      .finally(() => {
        // TODO: #11671 add error handling
        setIsNetworkStatePersisted(true);
      });
  }, [currentNetworkState]);

  if (
    // there is no service worker in dev mode, so do not block UI waiting for its activation
    (!isOfflineCapabilitiesFlagPersisted && !isDevMode) ||
    (areOfflineCapabilitiesEnabled &&
      !isDevMode &&
      // If the feature flag is turned on, we want to postpone rendering the UI until:
      // 1) Initial network state is detected
      // 2) Network state is persisted in IndexedDB (we're reading this value in SW)
      // 3) SW is activated
      // TODO: #11671 add error handling for points 2 & 3 and in such cases: log error and do not block UI for users
      (!isNetworkConnectionDetected || !isNetworkStatePersisted || !isServiceWorkerActivated))
  ) {
    return <Loading />;
  }

  return <>{children}</>;
};
