import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";
import { ReactElement, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { ReactReusableAutocomplete as Autocomplete } from "react-reusable-autocomplete";

import {
  getDefaultOrderName,
  getSelectOptionFromOrderTypeConfiguration,
  shouldRenderOrderTypes,
} from "@web/common";
import AnchorWhite from "@web/common/icons/AnchorWhite.svg";
import { OrderType } from "@web/common/models";
import { Port } from "@web/models";
import {
  CircledImage,
  DatePicker,
  Heading,
  Input,
  Label,
  OptionType,
  Paragraph,
  RegularButton,
  Select,
  TimePicker,
  containerPadding,
} from "@web/ui";
import { convertTimeToISODateString } from "@web/utils";

import { LiteCatalogConfiguration } from "src/typegens";

import { DutyFreeDeclaration } from "./DutyFreeDeclaration";
import { Form, FormValues } from "./models";

type Props = {
  onSubmitForm: (form: Form) => void;
  ports: Port[];
  preselectedOrderType?: OrderType;
  preselectedPort?: Port;
  isPunchOutIntegration?: boolean;
  orderTypes?: LiteCatalogConfiguration[];
  renderAttentionInfo?: ({ selectedPort }: { selectedPort?: Port }) => ReactElement | null;
};

type PortProps = {
  port: Port;
  className?: string;
};

const Divisor = () => <hr className="my-1" />;

const PortSelectorResult = ({ port, className }: PortProps) => {
  return (
    <div className={classnames("flex items-center bg-white-500", className)}>
      <CircledImage className="inline" Icon={AnchorWhite} size={5} hashKey={port.id} />
      <div>
        <div className="flex">
          <Paragraph className="mr-2" size="200" weight="heavy">
            {port.locationCode}
          </Paragraph>
          <Paragraph color="text-textIcon-blackSecondary" size="200" weight="heavy">
            {port.name}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

const OrderTypeSelect = Select<OrderType | undefined>;
const SupplierSelect = Select<string | undefined>;

export const PreconfigureOrderForm = ({
  ports,
  onSubmitForm,
  preselectedOrderType,
  preselectedPort,
  isPunchOutIntegration,
  orderTypes,
  renderAttentionInfo,
}: Props) => {
  const isOrderNameEnabled = useFlag("order-name");
  const canRenderOrderTypes = shouldRenderOrderTypes(orderTypes) && !isPunchOutIntegration;
  const form = useForm<FormValues>({
    defaultValues: {
      port: preselectedPort,
      deliveryDate: {
        date: "",
        time: "09:00",
      },
      dutyFreeDeclaration: {
        dutyFree: undefined,
        name: "",
        position: "",
      },
      orderType: preselectedOrderType,
      orderName: "",
      supplier: "",
    },
    mode: "onBlur",
    shouldUnregister: true,
  });

  const { register, control, handleSubmit, formState, getValues, setValue, watch } = form;
  const { errors } = formState;
  const selectedPort = watch("port");
  const isDutyFreeEnabled = watch("port")?.dutyFree;
  const isDutyFree = watch("dutyFreeDeclaration.dutyFree");

  const orderTypeOptions: OptionType<OrderType>[] = useMemo(
    () =>
      (orderTypes || []).map((orderType) => getSelectOptionFromOrderTypeConfiguration(orderType)),
    [orderTypes]
  );

  const supplierOptions: OptionType<string>[] = useMemo(() => [], []);

  const getPort = (portId?: string) => {
    if (!portId) {
      return undefined;
    }

    return ports.find((port) => port.id === portId);
  };

  const onSubmit = () => {
    const { dutyFreeDeclaration, deliveryDate, orderType, ...values } = getValues();
    const result = {
      ...values,
      ...(!isPunchOutIntegration
        ? {
            deliveryDate: convertTimeToISODateString(deliveryDate?.time, deliveryDate?.date),
          }
        : {}),
      ...(isDutyFreeEnabled
        ? {
            dutyFreeDeclaration: {
              dutyFree: dutyFreeDeclaration.dutyFree === "yes",
              name: dutyFreeDeclaration.name,
              position: dutyFreeDeclaration.position,
            },
          }
        : {}),
      orderType,
      orderName: isOrderNameEnabled
        ? values.orderName || getDefaultOrderName(getPort(values.port?.id), deliveryDate?.date)
        : undefined,
    };

    onSubmitForm(result);
  };

  return (
    <div className={classnames(containerPadding, "mt-4.5 mb-8 flex flex-col items-center")}>
      <form
        className="flex flex-col gap-6 w-full sm:w-[28rem]"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isOrderNameEnabled && (
          <>
            <div className="flex flex-col gap-4">
              <Heading size="300">Order Name</Heading>
              <Controller
                name="orderName"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Order name" maxLength={128} withBorder />
                )}
              />
            </div>
            <Divisor />
          </>
        )}

        <div className="flex flex-col gap-5">
          <Heading size="300">
            Please select port of delivery, order type and supplier to view the available products
          </Heading>
          {canRenderOrderTypes && (
            <div className="flex flex-col gap-2">
              <Label size="100">Order Type</Label>
              <Controller
                name="orderType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select an order type",
                  },
                }}
                render={({ field }) => (
                  <OrderTypeSelect
                    {...field}
                    onChange={(option) => field.onChange(option.value)}
                    value={orderTypeOptions.find((option) => option.value === field.value)}
                    options={orderTypeOptions}
                    placeholder="Select order type..."
                    errorMessage={errors.orderType?.message}
                  />
                )}
              />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <Label size="100">Port of Delivery</Label>
            <Controller
              name="port"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select a port",
                },
              }}
              render={({ field }) => (
                <Autocomplete
                  className="bg-red-400"
                  data-testid="portSelector_port"
                  items={ports}
                  onSelect={(port: Port) => {
                    setValue(field.name, port, { shouldDirty: true, shouldTouch: true });
                  }}
                  inputSearchString={field.value?.name}
                  showClear={false}
                  showIcon={false}
                  styling={{
                    fontFamily:
                      '"Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                    fontWeight: "100",
                    backgroundColor: "white",
                    border: "1px solid #b8bbc3",
                    borderRadius: "6px",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                    hoverBackgroundColor: "#f3f4f6",
                    fontSize: "14px",
                    height: "40px",
                    padding: "0",
                    lineHeight: "150px",
                    margin: "0",
                    maxResults: "200",
                    width: "100%",
                    zIndex: 100,
                  }}
                  fuseOptions={{
                    shouldSort: true,
                    keys: ["_search"],
                    isCaseSensitive: false,
                    includeScore: true,
                  }}
                  formatResult={(port: Port) => <PortSelectorResult port={port} />}
                  resultStringKeyName={"name"}
                  placeholder={"Which port will you arrive at?"}
                  error={
                    errors?.port?.message && (
                      <Paragraph size="200" color="text-dangerDefault">
                        {errors?.port?.message}
                      </Paragraph>
                    )
                  }
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label size="100">Supplier</Label>
            <Controller
              name="supplier"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select a supplier",
                },
              }}
              render={({ field }) => (
                <SupplierSelect
                  {...field}
                  onChange={(option) => field.onChange(option.value)}
                  value={supplierOptions.find((option) => option.value === field.value)}
                  options={supplierOptions}
                  placeholder="Select supplier..."
                  errorMessage={errors.supplier?.message}
                />
              )}
            />
          </div>
          {renderAttentionInfo &&
            renderAttentionInfo({
              selectedPort,
            })}
        </div>
        <Divisor />
        {!isPunchOutIntegration && (
          <>
            {/* TODO: #11699 - refactor DeliveryDate and use it here */}
            <div className="flex flex-col gap-4">
              <Heading size="300">Desired Delivery Date</Heading>
              <div className="flex gap-4">
                <Controller
                  name="deliveryDate.date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter a delivery date",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        label="Date"
                        additionalProps={{}}
                        onChange={field.onChange}
                        dataTestId="deliveryDate_datePicker"
                        error={
                          errors?.deliveryDate?.date?.message && (
                            <Paragraph size="200" color="text-dangerDefault">
                              {errors?.deliveryDate?.date?.message}
                            </Paragraph>
                          )
                        }
                        placeholder="Set an arrival date"
                      />
                    );
                  }}
                />
                <Controller
                  name="deliveryDate.time"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^(0?[0-9]|1[0-9]|(2[0-3])):[0-5][0-9]$/gm,
                      message: "Incorrect format of the time",
                    },
                    required: {
                      value: true,
                      message: "Please enter a delivery time",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <TimePicker
                        label="Time"
                        additionalProps={{}}
                        onChange={field.onChange}
                        initialValue="09:00"
                        dataTestId="deliveryDate_timePicker"
                        description="Specified as local time at the port"
                        placeholder="09:00"
                        error={
                          errors?.deliveryDate?.time?.message && (
                            <Paragraph size="200" color="text-dangerDefault">
                              {errors?.deliveryDate?.time?.message}
                            </Paragraph>
                          )
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>
            <Divisor />
          </>
        )}
        {isDutyFreeEnabled && (
          <>
            <DutyFreeDeclaration isDutyFree={isDutyFree} errors={errors} register={register} />
            <Divisor />
          </>
        )}
        <div className="flex justify-end">
          <RegularButton
            variant="primary"
            size="large"
            label="Start shopping"
            type="submit"
            data-testid="preconfigureOrderSetup_submit"
          />
        </div>
      </form>
    </div>
  );
};
