import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteConfiguration } from "src/typegens";

import { LiteAttentionInfoFleetsConfigEntrySchema } from "./LiteAttentionInfoFleetsConfigEntrySchema";
import { LiteBasketAnswersFormSchema } from "./LiteBasketAnswersFormSchema";
import { LiteCatalogConfigurationSchema } from "./LiteCatalogConfigurationSchema";
import { LiteFleetSchema } from "./LiteFleetSchema";
import { LiteInvoiceAccountSchema } from "./LiteInvoiceAccountSchema";
import { LiteOrderTypeConfigurationSchema } from "./LiteOrderTypeConfigurationSchema";
import { LitePortConfigurationSchema } from "./LitePortConfigurationSchema";
import { LitePortSchema } from "./LitePortSchema";
import { LiteSupplierSchema } from "./LiteSupplierSchema";
import { LiteTermsOfUseSchema } from "./LiteTermsOfUseSchema";
import { LiteUnitOfMeasureSchema } from "./LiteUnitOfMeasureSchema";
import { LiteVesselSchema } from "./LiteVesselSchema";
import { LiteWarehouseSchema } from "./LiteWarehouseSchema";
import { OrderStocktakeTypeSchema } from "./OrderStocktakeTypeSchema";

export const LiteConfigurationSchema = z.object({
  vessel: LiteVesselSchema,
  fleet: LiteFleetSchema,
  unitOfMeasures: z.array(LiteUnitOfMeasureSchema),
  warehouses: z.array(LiteWarehouseSchema),
  invoiceAccounts: z.array(LiteInvoiceAccountSchema),
  basketAnswersForm: LiteBasketAnswersFormSchema.optional(),
  availableStocktakeTypes: z.array(OrderStocktakeTypeSchema),
  termsOfUse: LiteTermsOfUseSchema.optional(),
  orderTypeConfigs: z.array(LiteOrderTypeConfigurationSchema),
  // @deprecated
  ports: z.record(z.array(LitePortSchema)),
  // @deprecated
  portConfiguration: z.record(LitePortConfigurationSchema),
  // @deprecated
  suppliers: z.record(LiteSupplierSchema),
  // @deprecated
  attentionInfo: z.array(LiteAttentionInfoFleetsConfigEntrySchema),
  // @deprecated
  orderTypes: z.array(LiteCatalogConfigurationSchema).optional(),
});

export type LocalLiteConfiguration = z.infer<typeof LiteConfigurationSchema>;

assertTypeEquality<LocalLiteConfiguration, LiteConfiguration, LocalLiteConfiguration>();
