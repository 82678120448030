import { UseFieldArrayRemove } from "react-hook-form";

import { UnitOfMeasure } from "@web/models";

import { StocktakeFormNewExtraProductTile } from "src/components/product-tile/stocktake/StocktakeFormNewExtraProductTile";
import { LocalStocktakeReportNewExtraItemForm } from "src/models";

type Props = {
  extraItems: Array<LocalStocktakeReportNewExtraItemForm>;
  removeNewExtraItem: UseFieldArrayRemove;
  unitOfMeasures: UnitOfMeasure[];
};

export const StocktakeNewExtraItemsList = ({
  extraItems,
  removeNewExtraItem,
  unitOfMeasures,
}: Props) => {
  return (
    <>
      {extraItems.map((extraItem, index) => (
        <StocktakeFormNewExtraProductTile
          key={extraItem.id}
          product={extraItem}
          indexInCollection={index}
          remove={removeNewExtraItem}
          hasLineNumberPlaceholder={true}
          unitOfMeasures={unitOfMeasures}
        />
      ))}
    </>
  );
};
