import { memo, useMemo } from "react";
import { useFormContext, useFormState } from "react-hook-form";

import { BasketExtraItemsFieldPath, LocalBasketForm, LocalLiteOrderExtraItem } from "src/models";

import { ProductTileFormErrors } from "../../ProductTileFormErrors";

type Props = {
  indexInCollection: number;
  itemsFieldPath: BasketExtraItemsFieldPath;
};

export const FormErrors = memo(({ indexInCollection, itemsFieldPath }: Props) => {
  const { control } = useFormContext<LocalBasketForm>();
  const { errors } = useFormState({ control });
  const itemsErrors = errors[itemsFieldPath];

  const errorMessages: Array<{ fieldName: string; message: string }> = useMemo(() => {
    const itemErrors = itemsErrors?.[indexInCollection] as
      | {
          [Property in keyof LocalLiteOrderExtraItem]: {
            ref?: { name?: string };
            message?: string;
          };
        }
      | undefined;

    if (!itemErrors) {
      return [];
    }

    return [
      ...(itemErrors.name && itemErrors.name.message
        ? [{ fieldName: "name", message: itemErrors.name.message }]
        : []),
      ...(itemErrors.quantity && itemErrors.quantity.message
        ? [{ fieldName: "quantity", message: itemErrors.quantity.message }]
        : []),
      ...(itemErrors.measurementUnit && itemErrors.measurementUnit.message
        ? [{ fieldName: "measurementUnit", message: itemErrors.measurementUnit.message }]
        : []),
      ...(itemErrors.refProductCode && itemErrors.refProductCode.message
        ? [{ fieldName: "refProductCode", message: itemErrors.refProductCode.message }]
        : []),
      ...(itemErrors.refUrl && itemErrors.refUrl.message
        ? [{ fieldName: "refUrl", message: itemErrors.refUrl.message }]
        : []),
    ];
  }, [itemsErrors, indexInCollection]);

  return <ProductTileFormErrors errorMessages={errorMessages} />;
});
FormErrors.displayName = "FormErrors";
