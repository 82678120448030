import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { memo } from "react";

import { Paragraph } from "@web/ui";

type Props = {
  className?: string;
  errorMessages: Array<{ fieldName: string; message: string }>;
};

export const ProductTileFormErrors = memo(({ className, errorMessages }: Props) => {
  return errorMessages.length > 0 ? (
    <div
      className={classnames(
        className,
        "mt-1 flex flex-col gap-0.5 px-2 py-1 bg-dangerBackground rounded-md"
      )}
    >
      {errorMessages.map((errorMessage) => (
        <div key={errorMessage.fieldName} className="flex items-center gap-1">
          <ExclamationCircleIcon className="w-4 h-4 text-dangerDefault" />
          <Paragraph size="300">{errorMessage.message}</Paragraph>
        </div>
      ))}
    </div>
  ) : null;
});
ProductTileFormErrors.displayName = "ProductTileFormErrors";
