import { z } from "zod";

import { LiteSupplierConfigurationSchema } from "./LiteSupplierConfigurationSchema";

export const LitePortConfigSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.string(),
  locationCode: z.string(),
  suppliers: z.array(LiteSupplierConfigurationSchema),
});
