import { z } from "zod";

export const OrderStocktakeTypeSchema = z.union([
  z.literal("BONDED"),
  z.literal("PROVISIONS"),
  z.literal("STORES"),
  z.literal("WELFARE"),
  z.literal("DEFAULT"),
]);

export type OrderStocktakeType = z.infer<typeof OrderStocktakeTypeSchema>;
