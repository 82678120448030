import { createContext, useContext, useEffect, useReducer } from "react";
import { useMatch } from "react-router-dom";

import { usePersistedState } from "@web/common/hooks";
import { usePrevious } from "@web/utils";

import { BASKET_STORAGE_KEY } from "src/config/constants";
import { RoutesConfig } from "src/config/routes";
import useBasket from "src/hooks/useBasket";

import {
  BasketDispatch,
  BasketEntry,
  BasketState,
  BasketStateStorage,
  BasketStateStorageSchema,
} from "./models";
import { basketStateReducer } from "./reducer";

export const defaultBasketState: BasketState = {
  lineItems: [],
  rfqItems: {},
  extraItems: [],
};

export const BasketContext = createContext<
  { state: BasketState; dispatch: BasketDispatch } | undefined
>(undefined);

type CategoriesProviderProps = { children: React.ReactNode };

export const BasketProvider: React.FC<CategoriesProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(basketStateReducer, defaultBasketState);
  const value = { state, dispatch };

  return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>;
};

export const useBasketState = () => {
  const context = useContext(BasketContext);
  if (context === undefined) {
    throw new Error("useBasketState must be used within a BasketContext");
  }
  return context;
};

export const BasketStateLifecycle: React.FC = () => {
  const { state, dispatch } = useBasketState();
  const { clearOrderRequisitionFlow } = useBasket();

  const isCategoryRoute = !!useMatch(`${RoutesConfig.category}/:categoryId`);
  const isSearchRoute = !!useMatch(RoutesConfig.search);
  const isBasketRoute = !!useMatch(RoutesConfig.basket);
  const isRequisitionInfoRoute = !!useMatch(RoutesConfig.requisitionInfo);
  const isOrderInfoRoute = !!useMatch(RoutesConfig.orderInfo);
  // Do not clear on gatherSetup since there may be a port or order type preselected in the Reorder feature flow
  const isGatherSetupRoute = !!useMatch(RoutesConfig.gatherSetup);

  const isClearingRoute =
    !isCategoryRoute &&
    !isSearchRoute &&
    !isBasketRoute &&
    !isRequisitionInfoRoute &&
    !isOrderInfoRoute &&
    !isGatherSetupRoute;
  const prevIsClearingRoute = usePrevious(isClearingRoute);

  useEffect(() => {
    if (isClearingRoute && isClearingRoute !== prevIsClearingRoute) {
      clearOrderRequisitionFlow();
    }
  }, [clearOrderRequisitionFlow, isClearingRoute, prevIsClearingRoute]);

  usePersistedState(
    BASKET_STORAGE_KEY,
    state,
    (data: BasketStateStorage) => {
      dispatch({
        type: "setBasketState",
        value: {
          ...data,
          extraItems: data.extraItems || [],
          // TODO #11890: Remove type coercion once BasketStateStorageSchema ensures proper `lineItems` type
          lineItems: (data.lineItems as unknown as BasketEntry[]).map((item) => ({
            ...item,
            sku: {
              ...item.sku,
              // TODO #11983: These defaults are needed to upgrade any LS stored basket that existed before enabling Select Supplier feature.
              // It's safe to remove this guard after some months after Select Supplier feature is enabled.
              supplierInformation: item.sku.supplierInformation || {
                // Try to copy ID from the now-deprecated `supplier` field
                supplierId: item.sku?.supplier?.supplierId || "",
                supplierItemId: "",
                name: "",
              },
            },
          })) as unknown as BasketEntry[],
        },
      });
    },
    BasketStateStorageSchema
  );

  return null;
};
