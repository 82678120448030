import Dexie from "dexie";

import { getDefaultOrderName } from "@web/common";
import { isDefined } from "@web/utils";

import { LocalLiteOrderRequisition } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalOrdersDatabase extends Dexie {
  orders: Dexie.Table<LocalLiteOrderRequisition, string>;

  constructor() {
    super(getStoreName(StoreNames.ORDERS));
    this.version(0.1).stores({
      orders: ",vessel.id",
    });
    this.version(0.2)
      .stores({
        orders: ",vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteOrderRequisition>("orders")
          .toCollection()
          .modify((order) => {
            if (!order.orderType) {
              order.orderType = "DEFAULT";
            }
          });
      });
    this.version(0.3)
      .stores({
        orders: ",vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteOrderRequisition>("orders")
          .toCollection()
          .modify((order) => {
            if (!isDefined(order.extraItems)) {
              order.extraItems = [];
            }
          });
      });
    this.version(0.4)
      .stores({
        orders: ",vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteOrderRequisition>("orders")
          .toCollection()
          .modify((order) => {
            if (!isDefined(order.subject)) {
              order.subject = getDefaultOrderName(order.port, order.deliveryDate);
            }
          });
      });
    this.version(0.5)
      .stores({
        orders: ",vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteOrderRequisition>("orders")
          .toCollection()
          .modify((order) => {
            if (!isDefined(order.supplierInformation)) {
              // It should never be a case even for old orders, since RFQ feature
              // was never enabled to the users.
              // However, since we changed the `supplierInformation` field to a required field,
              // we need to ensure it exists in the data.
              // Otherwise, we may run into a schema parsing error with some legacy edge case data.
              order.supplierInformation = {
                id: "",
                name: "",
              };
            }
          });
      });
  }
}

export const ordersStore = new LocalOrdersDatabase().orders;
