import { PlusIcon } from "@heroicons/react/solid";
import { ComponentProps, useCallback } from "react";
import { UseFieldArrayPrepend, UseFieldArrayRemove } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { UnitOfMeasure } from "@web/models";
import { Heading, Paragraph, RegularButton } from "@web/ui";
import { formatNumber } from "@web/utils";

import { BasketFormExtraProductTile } from "src/components/product-tile/basket/BasketFormExtraProductTile";
import useBasket from "src/hooks/useBasket";
import { LocalBasketExtraItemForm, LocalBasketForm } from "src/models";

type Props = ComponentProps<"div"> & {
  extraItems: Array<LocalBasketExtraItemForm>;
  prependExtraItem: UseFieldArrayPrepend<LocalBasketForm, "extraItems">;
  removeExtraItem: UseFieldArrayRemove;
  unitOfMeasures: UnitOfMeasure[];
};

export const BasketExtraItemsSection = ({
  className,
  extraItems,
  prependExtraItem,
  removeExtraItem,
  unitOfMeasures,
}: Props) => {
  const { t } = useTranslation();
  const { getEmptyExtraItem } = useBasket();
  const numberOfExtraItems = extraItems.length;

  const createNewItem = useCallback(() => {
    prependExtraItem(getEmptyExtraItem());
  }, [getEmptyExtraItem, prependExtraItem]);

  return (
    <div className={className}>
      <hr />
      <div className="flex flex-col gap-1 items-center my-6.5">
        <Heading size="300">Didn&apos;t find what you are looking for?</Heading>
        <Paragraph size="300">Try requesting a product</Paragraph>
      </div>
      <div className="flex justify-between items-center">
        <Heading size="300">
          Extra Items{" "}
          <span className="text-textIcon-blackSecondary">
            (
            {t("pages.basket.nrItems", {
              count: numberOfExtraItems,
              formattedCount: formatNumber(numberOfExtraItems),
            })}
            )
          </span>
        </Heading>
        <RegularButton
          size="large"
          variant="secondary"
          LeadingIcon={PlusIcon}
          label="Create Extra Item"
          onClick={createNewItem}
        />
      </div>
      <Paragraph size="100" className="mt-2">
        Please be aware that this could increase order processing time and trigger a quoting
        process.
      </Paragraph>
      {extraItems.length > 0 && (
        <div className="flex flex-col gap-2 mt-4">
          {extraItems.map((extraItem, index) => (
            <BasketFormExtraProductTile
              key={extraItem.id}
              product={extraItem}
              indexInCollection={index}
              remove={removeExtraItem}
              unitOfMeasures={unitOfMeasures}
            />
          ))}
        </div>
      )}
    </div>
  );
};
