import classNames from "classnames";
import { useCallback } from "react";

import Vector from "@web/common/icons/Vector.svg";
import { Heading, Label, Paragraph, RegularButton } from "@web/ui";
import { formatMoney } from "@web/utils";

import {
  LocalLitePunchoutAvailableItem,
  LocalLitePunchoutBasket,
  LocalLitePunchoutUnavailableItem,
} from "src/models";

interface Props {
  repunchoutBasket: LocalLitePunchoutBasket;
  handleRepunchoutContinue: (availableItems: LocalLitePunchoutAvailableItem[]) => void;
}

export const RepunchoutModal = ({ repunchoutBasket, handleRepunchoutContinue }: Props) => {
  const onContinue = useCallback(
    () => handleRepunchoutContinue(repunchoutBasket.availableItems),
    [handleRepunchoutContinue, repunchoutBasket.availableItems]
  );

  return (
    <div className="bg-neutral_300 h-full w-full flex items-center justify-center">
      <div className="rounded-lg shadow-lg w-[50vw] h-[70vh] bg-white overflow-y-auto">
        <div className="w-[100%] bg-white p-5 rounded-t-lg">
          <Heading size="200">Availability of Items</Heading>{" "}
          <div className="flex flex-row mt-2">
            <div>
              <Label size="200">Review the availability of items</Label>
              <Paragraph size="300" color="text-textIcon-blackSecondary">
                Items that are not available will not be added to your basket
              </Paragraph>
            </div>
            <div className="ml-auto">
              <Paragraph size="300" className="mb-2" color="text-textIcon-blackSecondary">
                Original Delivery Port
              </Paragraph>
              {repunchoutBasket.port ? (
                <div className="flex">
                  <img src={Vector} alt="Vector Icon" style={{ paddingLeft: "0.1em" }} />
                  <Paragraph size="200" color="text-neutral_700" className="pl-3">
                    {repunchoutBasket.port.locationCode}
                    <br />
                    <span className="text-textIcon-blackPrimary">{repunchoutBasket.port.name}</span>
                  </Paragraph>
                </div>
              ) : (
                <Paragraph size="200" color="text-neutral_700">
                  Unknown
                </Paragraph>
              )}
            </div>
          </div>
          <Paragraph size="100" color="text-secondaryDefault">
            {`${repunchoutBasket.availableItems.length} / ${repunchoutBasket.availableItems.length} of your Basket Items are available`}
          </Paragraph>
        </div>
        <div className="w-[100%] bg-neutral_100 p-5">
          {repunchoutBasket.unavailableItems.length > 0 && (
            <div className="w-[100%]">
              <Paragraph size="200" className="mb-3 font-medium">
                NOT AVAILABLE
              </Paragraph>
              {repunchoutBasket.unavailableItems.map(
                (product: LocalLitePunchoutUnavailableItem) => {
                  return (
                    <div
                      className="bg-neutral_200 py-2 px-5 w-[100%] text-gray-500 border border-neutral_200 rounded-md mb-2"
                      key={product.itemId}
                    >
                      <div className="flex w-[100%] items-center justify-between">
                        <div className="w-[400px]">
                          <Label size="200">{product.name}</Label>
                          <div className="text-sm">
                            {product.impaCode && `IMPA: ${product.impaCode}`}
                            Item ID: {product.supplierItemId}
                          </div>
                        </div>
                        <div>Total: {`${product.quantity} ${product.displayUnit}`}</div>
                        <div className="float-right">
                          <div className="text-md">
                            {formatMoney({
                              amount: product.unitPrice.amount * product.quantity,
                              currencyCode: product.unitPrice.currencyCode,
                            })}
                          </div>
                          <div className="text-sm">
                            {formatMoney(product.unitPrice)} / {product.displayUnit}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )}
          {repunchoutBasket.availableItems.length > 0 && (
            <div>
              <Paragraph
                size="200"
                className={classNames("mb3 font-medium", {
                  "mt-8": repunchoutBasket.unavailableItems.length > 0,
                })}
              >
                AVAILABLE
              </Paragraph>
              {repunchoutBasket.availableItems.map((product: LocalLitePunchoutAvailableItem) => {
                return (
                  <div
                    className="bg-neutral_200 py-2 px-5 w-[100%] text-gray-500 border border-neutral_200 rounded-md mb-2"
                    key={product.details.id}
                  >
                    <div className="flex w-[100%] items-center justify-between">
                      <div className="w-[400px]">
                        <Label size="200">{product.name}</Label>
                        <div className="text-sm">
                          {product.details.about?.generalInformation?.impaCode &&
                            `IMPA: ${product.details.about.generalInformation.impaCode}`}
                          Item ID: {product.details.supplierInformation.supplierItemId}
                        </div>
                      </div>
                      <div>
                        <div>Total: {`${product.quantity} ${product.details.measurementUnit}`}</div>
                      </div>
                      <div className="float-right">
                        <div className="text-md">
                          {formatMoney({
                            amount: product.unitPrice.amount * product.quantity,
                            currencyCode: product.unitPrice.currencyCode,
                          })}
                        </div>
                        <div className="text-sm">
                          {formatMoney(product.unitPrice)} / {product.details.measurementUnit}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="w-[100%] p-5 mb-6">
          <RegularButton
            className="float-right"
            variant="primary"
            size="large"
            width="content"
            label="Continue"
            onClick={onContinue}
          />
        </div>
      </div>
    </div>
  );
};
