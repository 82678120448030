import { z } from "zod";

import { CommentTypeSchema } from "./CommentTypeSchema";

export const LiteCommentSchema = z.object({
  name: z.string(),
  date: z.string(),
  type: CommentTypeSchema,
  origin: z.union([z.literal("BUYER"), z.literal("SUPPLIER")]),
  text: z.string(),
});
