import { useQuery } from "@tanstack/react-query";

import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { OfflineCatalogService } from "src/services/OfflineCatalogService";

export const useOfflineCatalogMetaData = () => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();

  return useQuery<Map<string, { updatedAt: string }>>({
    queryKey: ["offlineCatalogMetaData"],
    queryFn: async () => {
      const response = areOfflineCapabilitiesEnabled
        ? await OfflineCatalogService.getOfflineCatalogListMetaData()
        : new Map();
      return response;
    },
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
