import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";

import { InvoiceAccountUI, OrderReqService, OrderRequisition } from "@web/common";
import { Label, Paragraph } from "@web/ui";

type Props = {
  orders: OrderRequisition[];
  className?: string;
};

export const PlacedOrdersSummary = ({ orders, className }: Props) => {
  const isOrderNameEnabled = useFlag("order-name");
  const hasSelectSupplierFeature = useFlag("select-supplier");

  if (orders.length === 0) {
    return null;
  }

  return (
    <div className={classnames(className, "w-full px-4 sm:w-[28rem] sm:px-0 mx-auto")}>
      <hr />

      {orders.map((order, index) => {
        const isLast = index === orders.length - 1;
        const nrLineItems = order.items.length;
        const nrExtraItems = order.extraItems.length;
        const orderTotalGrossAmountFormatted = OrderReqService.getTotalGrossAmount(order);

        return (
          <div key={order.id}>
            {!!order.invoiceAccount && (
              <div className="mt-5">
                <Label size="200" color="text-textIcon-blackSecondary">
                  Billing Information
                </Label>
                <InvoiceAccountUI value={order.invoiceAccount} />
                <hr className="mt-5" />
              </div>
            )}

            {isOrderNameEnabled && (
              <div className="mt-5">
                <Label size="200" color="text-textIcon-blackSecondary">
                  Order Name
                </Label>
                <Paragraph size="100" weight="heavy">
                  {order.subject}
                </Paragraph>
                <hr className="mt-5" />
              </div>
            )}

            <div className="flex flex-col gap-3 mt-5">
              <div>
                <Label size="200" color="text-textIcon-blackSecondary">
                  {hasSelectSupplierFeature
                    ? `${order.supplierInformation.name} - Catalog\u00A0Items`
                    : "Catalog Items"}
                </Label>
                <div className="flex justify-between items-center">
                  <Paragraph size="200">
                    {nrLineItems} {`Item${nrLineItems > 1 ? "s" : ""}`}
                  </Paragraph>
                  <Paragraph size="200">{orderTotalGrossAmountFormatted}</Paragraph>
                </div>
              </div>
              {nrExtraItems > 0 && (
                <div>
                  <Label size="200" color="text-textIcon-blackSecondary">
                    Extra Items
                  </Label>
                  <div className="flex justify-between items-center">
                    <Paragraph size="200">
                      {nrExtraItems} {`Item${nrExtraItems > 1 ? "s" : ""}`}
                    </Paragraph>
                    <Paragraph size="200">TBD</Paragraph>
                  </div>
                </div>
              )}
              {nrLineItems > 0 && nrExtraItems > 0 && (
                <div>
                  <Label size="200">Total Items</Label>
                  <div className="flex justify-between items-center">
                    <Paragraph size="200">
                      {nrLineItems + nrExtraItems}{" "}
                      {`Item${nrLineItems + nrExtraItems > 1 ? "s" : ""}`}
                    </Paragraph>
                    <Paragraph size="200">{orderTotalGrossAmountFormatted}</Paragraph>
                  </div>
                </div>
              )}
            </div>
            <hr className="mt-5" />

            <div className="flex justify-between items-center mt-5">
              <Paragraph size="200">Sales Tax VAT 0%</Paragraph>
              <Paragraph size="200">
                {OrderReqService.getAmountZeroInOrderCurrency(order)}
              </Paragraph>
            </div>
            <hr className="mt-5" />

            <div className="flex justify-between items-center mt-5">
              <Paragraph size="100" weight="heavy">
                Total
              </Paragraph>
              <Paragraph size="100" weight="heavy">
                {orderTotalGrossAmountFormatted}
              </Paragraph>
            </div>
            <hr className="mt-5" />

            {!!order.storageLabel && (
              <>
                <div className="mt-5">
                  <Label size="200" color="text-textIcon-blackSecondary">
                    Storage Label
                  </Label>
                  <Paragraph size="200">{order.storageLabel}</Paragraph>
                </div>
                <hr className="mt-5" />
              </>
            )}

            {!isLast && <hr className="mt-1" />}
          </div>
        );
      })}
    </div>
  );
};
