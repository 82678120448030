import { z } from "zod";

export const LiteInvoiceAccountSchema = z.object({
  id: z.string(),
  taxNumber: z.string(),
  supplierInvoiceId: z.string(),
  registerOwner: z.string(),
  billingAddressLine1: z.string(),
  billingAddressLine2: z.string().optional(),
  billingAddressLine3: z.string().optional(),
  country: z.string(),
  approvalRequired: z.boolean(),
});
