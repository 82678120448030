import Dexie from "dexie";

import { StoreNames, getStoreName } from "../config";

export class LocalImagesDatabase extends Dexie {
  images: Dexie.Table<Blob, string>;

  constructor() {
    super(getStoreName(StoreNames.IMAGES));
    this.version(0.1).stores({
      images: "",
    });
  }
}

export const imagesStore = new LocalImagesDatabase().images;
