import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteProduct } from "src/typegens";

import { LiteImageSchema } from "./LiteImageSchema";
import { LiteProductBrandSchema } from "./LiteProductBrandSchema";
import { LiteProductCategoryNodeSchema } from "./LiteProductCategoryNodeSchema";
import { LiteProductSkuSchema } from "./LiteProductSkuSchema";
import { LiteProductSupplierInformationSchema } from "./LiteProductSupplierInformationSchema";

export const LiteProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  brand: LiteProductBrandSchema.optional(),
  images: z.array(LiteImageSchema),
  skuList: z.array(LiteProductSkuSchema),
  categoryTree: z.array(LiteProductCategoryNodeSchema),
  supplierInformation: LiteProductSupplierInformationSchema,
});

export type LocalLiteProduct = z.infer<typeof LiteProductSchema>;

assertTypeEquality<LocalLiteProduct, LiteProduct, LocalLiteProduct>();
