import classnames from "classnames";
import { useState } from "react";

import { formatShortString } from "@web/utils";

import { LinkAnchor, Paragraph } from "../../atoms";

type LongTextWrapperProps = {
  text: string;
  className?: string;
  lengthLimit?: number;
  showMoreLabel?: string;
  showLessLabel?: string;
  paragraphSize?: "100" | "200" | "300" | "400";
};

export const LongTextWrapper = ({
  text,
  className,
  showMoreLabel = "Show more",
  showLessLabel = "Show less",
  lengthLimit = 4,
  paragraphSize = "100",
}: LongTextWrapperProps) => {
  const [showAll, setShowAll] = useState(false);

  const renderLinks = () => {
    return text.length > lengthLimit ? (
      <LinkAnchor
        label={showAll ? showLessLabel : showMoreLabel}
        onClick={() => setShowAll(!showAll)}
        size="small"
        variant="primary"
      />
    ) : null;
  };

  return (
    <div className={classnames("w-full", className)} data-testid="longTextWrapper">
      <div>
        <Paragraph size={paragraphSize} className="whitespace-pre-line">
          {showAll ? text : formatShortString(text, lengthLimit)}
        </Paragraph>
      </div>
      {renderLinks()}
    </div>
  );
};
