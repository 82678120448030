import Dexie from "dexie";

import { getDefaultOrderName } from "@web/common";
import { isDefined } from "@web/utils";

import { LocalOrderDraft } from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalOrderDraftsDatabase extends Dexie {
  orderDrafts: Dexie.Table<LocalOrderDraft, string>;

  constructor() {
    super(getStoreName(StoreNames.ORDER_DRAFTS));
    this.version(0.1).stores({
      orderDrafts: "id,vessel.id",
    });
    this.version(0.2)
      .stores({
        orderDrafts: "id,vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalOrderDraft>("orderDrafts")
          .toCollection()
          .modify((orderDraft) => {
            if (!isDefined(orderDraft.extraItems)) {
              orderDraft.extraItems = [];
            }
          });
      });
    this.version(0.3)
      .stores({
        orderDrafts: "id,vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalOrderDraft>("orderDrafts")
          .toCollection()
          .modify((orderDraft) => {
            if (!isDefined(orderDraft.subject)) {
              orderDraft.subject = getDefaultOrderName(orderDraft.port, orderDraft.deliveryDate);
            }
          });
      });
    this.version(0.4)
      .stores({
        orderDrafts: "id,vessel.id",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalOrderDraft>("orderDrafts")
          .toCollection()
          .modify((orderDraft) => {
            if (!isDefined(orderDraft.supplierInformation)) {
              orderDraft.supplierInformation = {
                // Defaulting to empty string just in case if there are no line items
                // in the draft. This is an edge case for drafts created before the
                // Select Supplier was enabled, and which actually do not have any
                // line items in them when this migration runs.
                id: orderDraft.catalogItems[0]?.skuDetails?.supplier?.supplierId || "",
                // We don't have any data source to take the supplier name from
                // before the Select Supplier feature was enabled.
                // In this case, we leave it to the UI to handle empty supplier name field.
                name: "",
              };
            }
          });
      });
  }
}

const localOrderDraftsDatabaseInstance = new LocalOrderDraftsDatabase();

export const orderDraftsStore = localOrderDraftsDatabaseInstance.orderDrafts;
