import { ButtonHTMLAttributes, forwardRef, memo, useCallback } from "react";

import { UnitOfMeasure } from "@web/models";
import { DropdownItem, RegularDropdownItem } from "@web/ui";
import { WithRequiredProperty } from "@web/utils";

import { ProductTileSelect } from "../ProductTileSelect";
import { useFormFieldKeyboardEvents } from "../hooks";

type Props = {
  placeholder: string;
  value: string;
  unitOfMeasures: UnitOfMeasure[];
  setNewValue: (newValue: string) => void;
} & WithRequiredProperty<ButtonHTMLAttributes<HTMLButtonElement>, "aria-label">;

export const ProductTileUnitOfMeasureField = memo(
  forwardRef<HTMLButtonElement, Props>(
    (
      { "aria-label": ariaLabel, placeholder, value, unitOfMeasures, setNewValue, ...props },
      ref
    ) => {
      const formatUnitOfMeasure = useCallback(
        (unitOfMeasure: UnitOfMeasure) => `${unitOfMeasure.name} (${unitOfMeasure.code})`,
        []
      );

      const options: DropdownItem[] = unitOfMeasures.map((unitOfMeasure) => ({
        key: unitOfMeasure.code,
        renderComponent: () => (
          <RegularDropdownItem
            label={formatUnitOfMeasure(unitOfMeasure)}
            variant="basic"
            onClick={() => {
              setNewValue(unitOfMeasure.code);
            }}
          />
        ),
      }));

      const getCurrentValueLabel = useCallback(
        (unitOfMeasureCode: string) => {
          const currentValue = unitOfMeasures.find(
            (unitOfMeasure) => unitOfMeasure.code === unitOfMeasureCode
          );
          return currentValue ? formatUnitOfMeasure(currentValue) : undefined;
        },
        [formatUnitOfMeasure, unitOfMeasures]
      );

      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLButtonElement>();

      return (
        <ProductTileSelect
          {...props}
          ref={ref}
          currentValueLabel={getCurrentValueLabel(value)}
          placeholder={placeholder}
          options={options}
          onKeyDown={handleKeyDown}
          aria-label={ariaLabel}
        />
      );
    }
  )
);
ProductTileUnitOfMeasureField.displayName = "ProductTileUnitOfMeasureField";
