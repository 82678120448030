import _cloneDeep from "lodash/cloneDeep";

import { BasketEntry, BasketState, BasketStateAction } from "./models";

export const removeLineItemDuplicates = (
  fromList: Array<BasketEntry>,
  sourceList: Array<BasketEntry>
) => {
  const idsSet = new Set(sourceList.map((s) => s.sku.id));
  return fromList.filter(({ sku }) => !idsSet.has(sku.id));
};

export const basketStateReducer = (state: BasketState, action: BasketStateAction): BasketState => {
  switch (action.type) {
    case "setBasketState": {
      return {
        ...action.value,
      };
    }

    case "setCatalogItemInBasket": {
      const existingItem = state.lineItems.find(
        (lineItem) => lineItem.sku.id === action.value.sku.id
      );
      return {
        ...state,
        lastUpdated: Date.now(),
        lineItems: existingItem
          ? state.lineItems.map((item) =>
              item.sku.id === existingItem.sku.id ? action.value : item
            )
          : [...state.lineItems, action.value],
      };
    }

    case "addMultipleCatalogItemsToBasket": {
      const list = action.value;
      const { lineItems } = state;
      return {
        ...state,
        lastUpdated: Date.now(),
        lineItems: [...removeLineItemDuplicates(lineItems, list), ...list],
      };
    }

    case "removeCatalogItemFromBasket": {
      const existingItem = state.lineItems.find((lineItem) => lineItem.sku.id === action.value);
      if (!existingItem) {
        return state;
      }
      return {
        ...state,
        lastUpdated: Date.now(),
        lineItems: state.lineItems.filter((item) => item !== existingItem),
      };
    }

    case "removeMultipleEntriesFromBasket": {
      return {
        ...state,
        lastUpdated: Date.now(),
        lineItems: state.lineItems.filter((item) => !action.value.has(item.sku.id)),
        extraItems: state.extraItems.filter((item) => !action.value.has(item.id)),
        rfqItems: Object.values(state.rfqItems)
          .filter((item) => !action.value.has(item.id))
          .reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
      };
    }

    case "clearBasket": {
      return {
        ...state,
        basketAnswersForm: undefined,
        draft: undefined,
        lastUpdated: undefined,
        lineItems: [],
        rfqItems: {},
        extraItems: [],
      };
    }
    case "addRfqToBasket": {
      return {
        ...state,
        lastUpdated: Date.now(),
        rfqItems: {
          ...state.rfqItems,
          [action.value.id]: action.value,
        },
      };
    }
    case "removeRfqFromBasket": {
      const { [action.value]: remove, ...items } = state.rfqItems;
      return {
        ...state,
        lastUpdated: Date.now(),
        rfqItems: {
          ...items,
        },
      };
    }
    case "updateRfqInBasket": {
      return {
        ...state,
        lastUpdated: Date.now(),
        rfqItems: {
          ...state.rfqItems,
          [action.value.id]: action.value,
        },
      };
    }

    case "addMultipleRfqsToBasket": {
      return {
        ...state,
        lastUpdated: Date.now(),
        rfqItems: {
          ...state.rfqItems,
          ...action.value.reduce((acc, item) => {
            return { ...acc, [item.id]: item };
          }, {}),
        },
      };
    }

    case "setDraft": {
      return {
        ...state,
        draft: { ...action.value },
      };
    }

    // TODO #5641: Remove this case and fall back to `setDraft` everywhere when removing Online Draft logic
    case "setNewDraft": {
      if (
        action.value.status === "CREATION_STARTED" ||
        state.draft?.status === "CREATION_STARTED"
      ) {
        return {
          ...state,
          draft: { ...action.value },
        };
      }
      return state;
    }

    case "clearDraft": {
      return {
        ...state,
        draft: undefined,
      };
    }

    case "setBasketAnswersForm": {
      return {
        ...state,
        basketAnswersForm: action.value,
      };
    }

    case "setBasketExtraItems": {
      return {
        ...state,
        lastUpdated: Date.now(),
        extraItems: _cloneDeep(action.value),
      };
    }

    default:
      return state;
  }
};
