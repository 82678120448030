import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Input, Label, Modal, Textarea } from "@web/ui";

import { BASKET_ANSWERS_FORM_STORAGE_KEY } from "src/config/constants";
import { RoutesConfig } from "src/config/routes";
import useBasket from "src/hooks/useBasket";
import { LiteBasketAnswersForm } from "src/typegens";

import { BasketAnswersFormModal } from "../../../components/Modal/BasketAnswersFormModal";

type Props = {
  basketAnswersForm: LiteBasketAnswersForm;
  isBasketAnswersFormModalOpen: boolean;
  isFromDraft: boolean;
  onCloseModal: () => void;
};

type FormInputs = Record<string, string>;

const BasketAnswersFormCache = z.record(z.string());

const getPersistedBasketAnswersFormState = (): Record<string, string> => {
  try {
    const localBasketAnswersFormState = localStorage.getItem(BASKET_ANSWERS_FORM_STORAGE_KEY);
    const localBasketAnswersFormStateParsed = localBasketAnswersFormState
      ? JSON.parse(localBasketAnswersFormState)
      : {};

    BasketAnswersFormCache.parse(localBasketAnswersFormStateParsed);

    return localBasketAnswersFormStateParsed;
  } catch (err) {
    console.warn(err);
    localStorage.removeItem(BASKET_ANSWERS_FORM_STORAGE_KEY);

    return {};
  }
};

export const BasketAnswersFormModalController = ({
  basketAnswersForm,
  isBasketAnswersFormModalOpen,
  isFromDraft,
  onCloseModal,
}: Props) => {
  const navigate = useNavigate();
  const { setBasketAnswersForm } = useBasket();
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<FormInputs>({
    defaultValues: getPersistedBasketAnswersFormState(),
  });
  /**
    Handlers
   */
  const onSubmit: SubmitHandler<FormInputs> = (data: Record<string, string>) => {
    const answersForm = Object.entries(data)
      .map(([key, value]) => {
        return {
          number: Number(key),
          answer: value,
          question:
            basketAnswersForm.questions.find((question) => question.number === Number(key))
              ?.question || "",
        };
      })
      .filter((answer) => {
        return Boolean(answer.question);
      });
    setBasketAnswersForm(answersForm);
    navigate(`${RoutesConfig.requisitionInfo}${isFromDraft ? "?from=draft" : ""}`);
  };
  const handleCloseModal = () => {
    onCloseModal();
  };
  const persistFormValues = () => {
    localStorage.setItem(BASKET_ANSWERS_FORM_STORAGE_KEY, JSON.stringify(getValues()));
  };

  return (
    <Modal isOpen={isBasketAnswersFormModalOpen} closeModal={handleCloseModal}>
      <BasketAnswersFormModal
        closeModal={handleCloseModal}
        title={basketAnswersForm.title}
        comments={basketAnswersForm.comments}
        onSubmit={handleSubmit(onSubmit)}
        questions={basketAnswersForm.questions.map((question) => {
          const errorMesssage =
            errors[question.number]?.type === "required" ? "Required" : undefined;
          const testId = `basketForm_question_${question.number}`;

          return (
            <div className={`mb-4 ${errorMesssage && "pb-2"}`} key={question.number}>
              <div>
                <Label size="100">
                  {question.number}. {question.question}
                </Label>
                {question.required && (
                  <Label size="100" color="text-textIcon-blackSecondary">
                    {" "}
                    (required)
                  </Label>
                )}
              </div>
              <div>
                <Controller
                  render={({ field: { onChange, ...rest } }) => {
                    return question.multiline ? (
                      <Textarea
                        testId={testId}
                        errorMessage={errorMesssage}
                        onChange={(event) => {
                          onChange(event);
                          persistFormValues();
                        }}
                        {...rest}
                      />
                    ) : (
                      <Input
                        testId={testId}
                        errorMessage={errorMesssage}
                        onChange={(event) => {
                          onChange(event);
                          persistFormValues();
                        }}
                        withBorder
                        {...rest}
                      />
                    );
                  }}
                  name={String(question.number)}
                  control={control}
                  defaultValue=""
                  rules={{ required: question.required }}
                />
              </div>
            </div>
          );
        })}
      />
    </Modal>
  );
};
