import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";

import { Heading, Label, Paragraph } from "@web/ui";
import { formatMoney } from "@web/utils";

import { Vessel } from "../../models/Vessel";
import { OrderRequisition } from "../../network/model";

interface Props {
  order: Pick<
    OrderRequisition,
    "priceModifiers" | "items" | "extraItems" | "totalGrossAmount" | "supplierInformation"
  > & {
    vessel: Pick<Vessel, "name" | "imoNumber">;
  };
  className?: string;
}

export const PriceBox = ({ order, className }: Props) => {
  const hasSelectSupplierFeature = useFlag("select-supplier");

  const hasAnythingToShow =
    order.items.length > 0 ||
    order.extraItems.length > 0 ||
    (order.priceModifiers && order.priceModifiers.length > 0);

  if (!hasAnythingToShow) {
    return null;
  }

  const totalLineItemsPriceBeforePriceModifiersAmount: number = order.items.reduce(
    (prev, curr) => prev + curr.totalAmount.amount,
    0
  );

  const nrLineItems = order.items.length;
  const nrExtraItems = order.extraItems.length;

  return (
    <div
      className={classnames("px-5 py-5 border rounded-lg bg-neutral_0 flex flex-col", className)}
    >
      <Heading size="300">Price breakdown</Heading>

      {(nrLineItems > 0 || nrExtraItems > 0) && (
        <>
          <div className="flex flex-col gap-3 mt-5">
            {nrLineItems > 0 && (
              <div>
                <Label size="200" color="text-textIcon-blackSecondary">
                  {/* TODO #11983: Offline Drafts created before Select Supplier feature was enabled,
                  will have empty string as the supplier's name. It's safe to remove
                  this empty string guard after some months after Select Supplier
                  feature is enabled. */}
                  {hasSelectSupplierFeature && !!order.supplierInformation.name
                    ? `${order.supplierInformation.name} - Catalog\u00A0Items`
                    : "Catalog Items"}
                </Label>
                <div className="flex justify-between items-center">
                  <Paragraph size="200">
                    {nrLineItems} {`Item${nrLineItems > 1 ? "s" : ""}`}
                  </Paragraph>
                  <Paragraph size="200">
                    {formatMoney({
                      amount: totalLineItemsPriceBeforePriceModifiersAmount,
                      currencyCode: order.totalGrossAmount.currencyCode,
                    })}
                  </Paragraph>
                </div>
              </div>
            )}
            {nrExtraItems > 0 && (
              <div>
                <Label size="200" color="text-textIcon-blackSecondary">
                  Extra Items
                </Label>
                <div className="flex justify-between items-center">
                  <Paragraph size="200">
                    {nrExtraItems} {`Item${nrExtraItems > 1 ? "s" : ""}`}
                  </Paragraph>
                  <Paragraph size="200">TBD</Paragraph>
                </div>
                <Paragraph size="300" color="text-textIcon-blackSecondary" className="mt-1">
                  Please check the Excel file to view the Extra Items.
                </Paragraph>
              </div>
            )}
          </div>
          <hr className="mt-5" />
        </>
      )}

      {order.priceModifiers && order.priceModifiers.length > 0 && (
        <>
          <div className="flex flex-col gap-2 mt-5">
            {order.priceModifiers.map((priceModifier, index) => (
              <div key={index} className="flex justify-between items-center">
                <Label
                  size="200"
                  color="text-textIcon-blackSecondary"
                  className="block max-w-[60%]"
                >
                  <span className="block truncate max-w-full">{priceModifier.name}</span>
                </Label>
                <Paragraph size="200">
                  {formatMoney({
                    amount:
                      priceModifier.changeType === "DISCOUNT"
                        ? -priceModifier.amount.amount
                        : priceModifier.amount.amount,
                    currencyCode: priceModifier.amount.currencyCode,
                  })}
                </Paragraph>
              </div>
            ))}
          </div>
          <hr className="mt-5" />
        </>
      )}

      <div className="flex justify-between items-center mt-5">
        <Paragraph size="100" weight="heavy">
          Total Price
        </Paragraph>
        <Paragraph size="100" weight="heavy">
          {formatMoney({
            amount: order.totalGrossAmount.amount,
            currencyCode: order.totalGrossAmount.currencyCode,
          })}
        </Paragraph>
      </div>
    </div>
  );
};
