import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { OrderType, Vessel } from "@web/common";
import { isDefined } from "@web/utils";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import useBasket from "src/hooks/useBasket";
import { useToastMessage } from "src/hooks/useToastMessage";
import { BasketExtraItemFormSchema, LocalLiteOrderExtraItem } from "src/models";
import { LocalProductService } from "src/services/LocalProductService";
import { LifeRfqItem, LitePort } from "src/typegens";

type ValidatedOrderData = {
  port: LitePort;
  orderType: OrderType;
  catalogItems: Array<{ variantId: string; entityQuantity: number }>;
  extraItems: LocalLiteOrderExtraItem[];
  supplierId: string;
  vessel: Vessel;
  rfqItems: LifeRfqItem[];
};

type ValidateOrderWithSideEffectsArgs = {
  rfqItems: LifeRfqItem[];
};

type UseValidateCheckoutOrder = {
  validateOrderWithSideEffects: (
    args: ValidateOrderWithSideEffectsArgs
  ) => ValidatedOrderData | undefined;
};

export const useValidateCheckoutOrder = (): UseValidateCheckoutOrder => {
  const navigate = useNavigate();
  const { setToastMessage } = useToastMessage();

  const [{ configuration, port, orderType }] = useAppStateContext();
  const vessel = configuration?.vessel;
  const hasRfQFeature =
    configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
    configuration.fleet.allow.createRfq;

  const { getOrderItems, lineItems, extraItems } = useBasket();

  // Until the Select Supplier feature is ready, with RFQ feature turned on,
  // when there are no line items and only RFQ items in the basket,
  // the `supplierId` sent with such a requisition is an empty string,
  // and it's BE who defaults the `supplierId` on their side.
  const supplierId =
    lineItems[0]?.sku.supplierInformation.supplierId || (hasRfQFeature ? "" : undefined);

  const validateOrderWithSideEffects = useCallback(
    ({ rfqItems }: ValidateOrderWithSideEffectsArgs) => {
      // These are all edge cases and should never happen.
      if (!configuration) {
        setToastMessage({
          type: "failure",
          message:
            "No configuration, please try refreshing the page. If this does not help, please contact Customer Support.",
        });
        return;
      }
      if (!port) {
        setToastMessage({
          type: "failure",
          message: "No port selected. Please try again or contact Customer Support.",
        });
        return;
      }
      if (!orderType) {
        setToastMessage({
          type: "failure",
          message: "No order type selected. Please try again or contact Customer Support.",
        });
        return;
      }
      if (!vessel) {
        setToastMessage({
          type: "failure",
          message: "No vessel information. Please try again or contact Customer Support.",
        });
        return;
      }

      const requestCatalogItems = getOrderItems();

      // This is an edge case and should never happen.
      if (!hasRfQFeature && requestCatalogItems.length === 0) {
        setToastMessage({
          type: "failure",
          message:
            "There are no catalog items in the Basket. Please add at least 1 catalog item to the Basket to place your order.",
        });
        navigate(RoutesConfig.basket);
        return;
      }

      if (hasRfQFeature && requestCatalogItems.length === 0 && rfqItems.length === 0) {
        setToastMessage({
          type: "failure",
          message:
            "There are no items in the Basket. Please add at least 1 catalog or RFQ item to the Basket to place your requisition.",
        });
        navigate(RoutesConfig.basket);
        return;
      }

      // After the Select Supplier feature is done, the `!isDefined(supplierId)`
      // condition can be changed to `!supplierId` condition, since we will
      // no longer send empty string as `supplierId`.
      // This is an edge case and should never happen, since we check for line items
      // in the previous condition.
      if (!isDefined(supplierId)) {
        setToastMessage({
          type: "failure",
          message: "Unknown supplier. Please try again or contact Customer Support.",
        });
        return;
      }

      const requestExtraItems: LocalLiteOrderExtraItem[] =
        LocalProductService.convertLocalExtraItemsToApiRequest(extraItems);

      // This is an edge case and should never happen.
      const areExtraItemsValid = !requestExtraItems.some(
        (extraItem) => !BasketExtraItemFormSchema.safeParse(extraItem).success
      );
      if (!areExtraItemsValid) {
        setToastMessage({
          type: "failure",
          message: "Extra Items contain errors. Please correct them and go to checkout once again.",
        });
        navigate(RoutesConfig.basket);
        return;
      }

      return {
        port,
        orderType,
        catalogItems: requestCatalogItems,
        extraItems: requestExtraItems,
        supplierId,
        vessel,
        rfqItems,
      };
    },
    [
      configuration,
      extraItems,
      getOrderItems,
      hasRfQFeature,
      navigate,
      orderType,
      port,
      setToastMessage,
      supplierId,
      vessel,
    ]
  );

  return {
    validateOrderWithSideEffects,
  };
};
