import { QueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { isParsingError, isStorageError } from "@web/utils";

import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { isApiError, isOfflineCatalogError } from "src/utils";

import { useOfflineCapabilities } from "../OfflineCapabilities";

type Props = {
  render: (queryClient: QueryClient) => React.ReactNode;
};

const shouldQueryUseErrorBoundary = (error: unknown) =>
  (isApiError(error) && error.request.method === "GET") ||
  (isStorageError(error) && error.request.access === "read") ||
  isParsingError(error) ||
  isOfflineCatalogError(error);

const shouldMutationUseErrorBoundary = (error: unknown) => isParsingError(error);

export const QueryClientCreator = ({ render }: Props) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isNavigatorOnline } = useNetworkDetector();

  const queryClient = useMemo(
    () =>
      new QueryClient(
        areOfflineCapabilitiesEnabled
          ? {
              defaultOptions: {
                queries: {
                  networkMode: "always",
                  refetchOnWindowFocus: false,
                  refetchOnReconnect: false,
                  retry: (count) => {
                    if (count < 1) {
                      return true;
                    }
                    // Increase failure count to 4, when `!isNavigatorOnline`
                    // so our network detector mechanism can correctly
                    // detect offline state before query function failure
                    if (!isNavigatorOnline && count <= 4) {
                      return true;
                    }

                    return false;
                  },
                  throwOnError: shouldQueryUseErrorBoundary,
                },
                mutations: {
                  throwOnError: shouldMutationUseErrorBoundary,
                },
              },
            }
          : {
              defaultOptions: {
                queries: {
                  throwOnError: shouldQueryUseErrorBoundary,
                },
                mutations: {
                  throwOnError: shouldMutationUseErrorBoundary,
                },
              },
            }
      ),
    [areOfflineCapabilitiesEnabled, isNavigatorOnline]
  );

  return <>{render(queryClient)}</>;
};
