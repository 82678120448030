import { getCategory } from "@web/common/utils/categories";

import { useAppStateContext } from "../contexts/AppStateContext";
import useCategoriesQuery from "./useCategoriesQuery";
import { usePunchOutIntegration } from "./usePunchOutIntegration";

const useCategories = ({
  disabled = false,
}: {
  disabled?: boolean;
} = {}) => {
  const [{ port, orderType }] = useAppStateContext();
  const { supplierId } = usePunchOutIntegration();

  // NOTE: this is a hack, it will never be '' because for '' it will be disabled
  const { query, invalidate } = useCategoriesQuery(
    {
      portId: port?.id || "",
      // Supplier ID is allowed to be empty
      supplierId: supplierId || "",
      orderType: orderType,
    },
    { enabled: !!port?.id && !!orderType && !disabled }
  );

  return {
    categoriesQuery: query,
    invalidateQuery: invalidate,
    getCategory: (categoryId: string) => getCategory(categoryId, query.data || []).reverse()[0],
    getBreadcrumb: (categoryId: string) => getCategory(categoryId, query.data || []),
  };
};

export default useCategories;
