import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Money } from "@web/models";
import { isDefined, preprocessFormAmountValue } from "@web/utils";

import {
  LocalStocktakeReportForm,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

type UseItemFormDetails = {
  initialEntityQuantity: number;
  initialProductRobValue: Money | undefined;
  modifiedQuantity: string | number | undefined;
  modifiedSingleUnitGrossPriceAmount: string | number | undefined;
  modifiedMeasurementUnit: string;
  modifiedEntityQuantity: number;
  modifiedProductRobValue: Money | undefined;
};

type Params = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const useItemFormDetails = ({
  initialQuantity,
  initialMeasurementUnit,
  initialSingleUnitGrossPriceAmount,
  salesEntityQuantity,
  currencyCode,
  quantityFieldPath,
  singleUnitGrossPriceFieldPath,
  measurementUnitFieldPath,
}: Params): UseItemFormDetails => {
  const { control } = useFormContext<LocalStocktakeReportForm>();

  const initialEntityQuantity = LocalStocktakeService.getItemEntityQuantity(
    salesEntityQuantity,
    preprocessFormAmountValue(initialQuantity)
  );

  const initialProductRobValue = useMemo(() => {
    const processedInitialSingleUnitGrossPriceAmount = preprocessFormAmountValue(
      initialSingleUnitGrossPriceAmount
    );
    return isDefined(processedInitialSingleUnitGrossPriceAmount)
      ? {
          currencyCode: currencyCode,
          amount: LocalStocktakeService.getItemRobValueAmount(
            initialEntityQuantity,
            processedInitialSingleUnitGrossPriceAmount
          ),
        }
      : undefined;
  }, [currencyCode, initialEntityQuantity, initialSingleUnitGrossPriceAmount]);

  // Before submission, the model value can be a string, so let's reflect that knowledge in the coerced type
  const modifiedQuantity = useWatch({
    control,
    name: quantityFieldPath,
    defaultValue: initialQuantity,
  }) as string | number | undefined;

  // Before submission, the model value can be a string, so let's reflect that knowledge in the coerced type
  const modifiedSingleUnitGrossPriceAmount = useWatch({
    control,
    name: singleUnitGrossPriceFieldPath,
    defaultValue: initialSingleUnitGrossPriceAmount,
  }) as string | number | undefined;

  const modifiedMeasurementUnit = useWatch({
    control,
    name: measurementUnitFieldPath,
    defaultValue: initialMeasurementUnit,
  });

  // We are using locally calculated `entityQuantity` and `productRobValue` to avoid unnecessary re-renders
  // that would occur if we tried to take them directly from the form values
  const modifiedEntityQuantity = LocalStocktakeService.getItemEntityQuantity(
    salesEntityQuantity,
    preprocessFormAmountValue(modifiedQuantity)
  );

  const modifiedProductRobValue = useMemo(() => {
    const processedModifiedSingleUnitGrossPriceAmount = preprocessFormAmountValue(
      modifiedSingleUnitGrossPriceAmount
    );
    return isDefined(processedModifiedSingleUnitGrossPriceAmount)
      ? {
          currencyCode: currencyCode,
          amount: LocalStocktakeService.getItemRobValueAmount(
            modifiedEntityQuantity,
            processedModifiedSingleUnitGrossPriceAmount
          ),
        }
      : undefined;
  }, [modifiedSingleUnitGrossPriceAmount, currencyCode, modifiedEntityQuantity]);

  return {
    initialEntityQuantity,
    initialProductRobValue,
    modifiedQuantity,
    modifiedSingleUnitGrossPriceAmount,
    modifiedMeasurementUnit,
    modifiedEntityQuantity,
    modifiedProductRobValue,
  };
};
