import { EmptyBasketUI } from "@web/common/views/Basket/components/EmptyBasketUI";

import RequestProductInline from "src/components/RequestProductInline";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";

export const BasketEmpty: React.FC = () => {
  const [{ configuration }] = useAppStateContext();
  const productInline = configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
    configuration.fleet.allow.createRfq && <RequestProductInline />;

  return <EmptyBasketUI productInline={productInline} homeRoute={RoutesConfig.gatherSetup} />;
};
