import { useFlag } from "@unleash/proxy-client-react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { OrderInfo, OrderInfoFormValues } from "@web/common/pages";
import { getDefaultOrderName } from "@web/common/utils";
import { ActionBar, containerPadding } from "@web/ui";

import { AttentionInfo, orderExtraItemsTermsAttentionInfoItem } from "src/components/AttentionInfo";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import useBasket from "src/hooks/useBasket";
import { useBasketVerification } from "src/hooks/useBasketVerification";
import { useCreateOrderMutation } from "src/hooks/useCreateOrderMutation";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { useProductVariantsMutation } from "src/hooks/useProductVariantsMutation";
import AppState from "src/state/models";
import { LiteOrderCreationRequest } from "src/typegens";
import { useValidateCheckoutOrder } from "src/utils/useValidateCheckoutOrder";

export const PurchaseOrderSummary = () => {
  const [appState, dispatch] = useAppStateContext();
  const { draft, grandTotal, lineItems, extraItems } = useBasket();
  const vessel = (appState as Required<AppState>).configuration.vessel;
  const orderTypes = appState.configuration?.orderTypes;
  const port = appState.port;
  const isCustomerOrderIdRequired = !appState.configuration?.fleet.allow.customerOrderIdOptional;
  const orderType = appState.orderType;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDraftValid = searchParams.get("from") === "draft" && !!draft?.id;
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateOrderMutation(isDraftValid ? draft.id : undefined);
  const { mutateAsync: productVariantsAsyncMutation, isPending: isProductVariantsMutationPending } =
    useProductVariantsMutation();
  const { findChangedProducts, BasketVerificationModal } = useBasketVerification();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const isOrderNameEnabled = useFlag("order-name");

  const { validateOrderWithSideEffects } = useValidateCheckoutOrder();

  const submitOrder = async (orderSummary: OrderInfoFormValues) => {
    const validatedOrderData = validateOrderWithSideEffects({ rfqItems: [] });

    if (!validatedOrderData) {
      // Validation failed & all side effects have been triggered
      return;
    }

    const draftId = isDraftValid && !isOfflineDraftEnabled ? draft.id : undefined;

    const request: LiteOrderCreationRequest = {
      draftId,
      // From validated order data
      vesselId: validatedOrderData.vessel.id,
      supplierId: validatedOrderData.supplierId,
      items: validatedOrderData.catalogItems,
      extraItems: validatedOrderData.extraItems,
      portId: validatedOrderData.port.id,
      orderType: validatedOrderData.orderType,
      // From local form data
      ...(orderSummary.dutyFreeDeclaration
        ? {
            dutyFreeDeclaration: {
              dutyFree: orderSummary.dutyFreeDeclaration.dutyFree,
              name: orderSummary.dutyFreeDeclaration.name,
              position: orderSummary.dutyFreeDeclaration.position,
            },
          }
        : {}),
      agentInformation: orderSummary.agentInformation,
      consolidated: orderSummary.consolidated ?? false,
      customerOrderId: orderSummary.customerOrderId,
      deliveryDate: orderSummary.deliveryDate ?? "",
      invoiceAccountId: orderSummary.invoiceAccountId,
      orderNotes: orderSummary.orderNotes,
      requesterInformation: orderSummary.requesterInformation ?? {
        name: "",
        email: "",
      },
      storageLabel: orderSummary.storageLabel,
      warehouseId: orderSummary.warehouseId,
      subject:
        orderSummary.subject || getDefaultOrderName(appState.port, orderSummary.deliveryDate),
    };

    const skus = await productVariantsAsyncMutation({
      variantIds: lineItems.map((item) => item.sku.id),
      portId: validatedOrderData.port.id,
      orderType: validatedOrderData.orderType,
    });

    const { productsWithChangedPrice, unavailableProducts } = findChangedProducts({
      lineItems,
      skus,
      submitCallback: () => {
        mutate(request);
      },
    });

    if (unavailableProducts.length > 0 || productsWithChangedPrice.length > 0) {
      return;
    }

    mutate(request);
  };

  const submitOrderHandler = (orderSummary: OrderInfoFormValues) => {
    allowOnlineOnly(() => {
      submitOrder(orderSummary);
    });
  };

  const renderAttentionInfo = useCallback(
    ({ className }: { className: string }) => {
      const items = [
        ...(port?.attentionInfo || []),
        ...(extraItems.length > 0 ? [orderExtraItemsTermsAttentionInfoItem] : []),
      ];
      return items.length > 0 ? <AttentionInfo items={items} className={className} /> : null;
    },
    [extraItems.length, port?.attentionInfo]
  );

  return (
    <>
      <TopBarController />

      <div className="flex-grow bg-neutral_100">
        <ActionBar
          backButtonSettings={{
            title: "Return to Basket",
            onClick: () => {
              navigate(RoutesConfig.basket);
            },
          }}
        />
        <div className={`${containerPadding} flex py-3`}>
          <>
            <OrderInfo
              creationMode="ORDER_CREATION"
              grandTotal={grandTotal}
              invoiceAccounts={appState.configuration?.vessel.invoiceAccounts}
              warehouses={appState.configuration?.warehouses}
              deliveryDate={appState.deliveryDate}
              loading={isPending || isProductVariantsMutationPending}
              nrLineItems={lineItems.length}
              nrRfqItems={0}
              nrExtraItems={extraItems.length}
              port={port}
              submitForm={submitOrderHandler}
              vessel={vessel}
              dutyFreeDeclaration={appState.dutyFreeDeclaration}
              isCustomerOrderIdRequired={isCustomerOrderIdRequired}
              withOrderComment
              renderAttentionInfo={renderAttentionInfo}
              orderType={orderType}
              orderTypes={orderTypes}
              initialOrderName={appState.orderName}
              updateOrderNameInState={(value) => {
                dispatch({ type: "setOrderName", value });
              }}
              orderName={isOrderNameEnabled ? appState.orderName : ""}
              isOrderNameEnabled={isOrderNameEnabled}
            />
            <AllowOnlineOnlyWarningModal />
            <BasketVerificationModal />
          </>
        </div>
      </div>
    </>
  );
};
