import { z } from "zod";

import { ApiMoneySchema } from "./ApiMoneySchema";

export const LiteProductChangeSchema = z.object({
  lineNumber: z.number(),
  changeType: z.union([
    z.literal("ADDED"),
    z.literal("REMOVED"),
    z.literal("UPDATED"),
    z.literal("REPLACED"),
  ]),
  //
  id: z.string(),
  name: z.string(),
  quantity: z.number(),
  measurementUnit: z.string(),
  singleGrossPrice: ApiMoneySchema,
  entityQuantity: z.number(),
  supplierIdentifier: z.string(),
  //
  oldId: z.string().optional(),
  oldName: z.string().optional(),
  oldQuantity: z.number().optional(),
  oldMeasurementUnit: z.string().optional(),
  oldSingleGrossPrice: ApiMoneySchema.optional(),
  oldEntityQuantity: z.number().optional(),
  oldSupplierIdentifier: z.string().optional(),
});
