import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo, useState } from "react";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { LocalStocktakeType } from "@web/common";
import {
  DatePicker,
  Heading,
  Input,
  Modal,
  OptionType,
  Paragraph,
  RegularButton,
  Select,
} from "@web/ui";

import { StocktakeDraftsLimitExceededModal } from "src/components/Modal";
import { RoutesConfig } from "src/config/routes";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useToastMessage } from "src/hooks/useToastMessage";
import {
  LocalStocktakeReportDraft,
  LocalStocktakeSetupForm,
  StocktakeSetupFormSchema,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

type Props = {
  availableStocktakeTypes: LocalStocktakeType[];
  isPending: boolean;
  onValidatedFormSubmit: (validatedFormData: LocalStocktakeSetupForm) => void;
  existingDrafts: LocalStocktakeReportDraft[];
};

type StocktakeTypeOption = OptionType<LocalStocktakeType>;

export const StocktakeSetupForm = ({
  availableStocktakeTypes,
  isPending,
  onValidatedFormSubmit,
  existingDrafts,
}: Props) => {
  const { setToastMessage } = useToastMessage();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const [isDraftsLimitExceededModalOpen, setIsDraftsLimitExceededModalOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const openDraftsLimitExceededModal = useCallback(() => {
    setIsDraftsLimitExceededModalOpen(true);
  }, []);

  const closeDraftsLimitExceededModal = useCallback(() => {
    setIsDraftsLimitExceededModalOpen(false);
  }, []);

  const goToStocktakeOverview = useCallback(() => {
    navigate(RoutesConfig.stocktake.overview);
  }, [navigate]);

  const stocktakeTypeOptions: StocktakeTypeOption[] = useMemo(
    () =>
      (availableStocktakeTypes || []).map((type) =>
        LocalStocktakeService.getSelectOptionFromStocktakeType(type)
      ),
    [availableStocktakeTypes]
  );

  const canRenderStocktakeTypes =
    LocalStocktakeService.shouldRenderStocktakeTypes(availableStocktakeTypes);

  const isDraftsLimitExceeded = useCallback(
    (stocktakeType: LocalStocktakeType) =>
      canRenderStocktakeTypes
        ? existingDrafts.filter((draft) => draft.type === stocktakeType).length >= 1
        : existingDrafts.length >= 1,
    [canRenderStocktakeTypes, existingDrafts]
  );

  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState,
    getValues,
  } = useForm<LocalStocktakeSetupForm>({
    resolver: zodResolver(
      StocktakeSetupFormSchema.extend({
        type: StocktakeSetupFormSchema.shape.type.refine(
          (stocktakeType) => !isDraftsLimitExceeded(stocktakeType),
          { message: "Change type" }
        ),
      })
    ),
    defaultValues: {
      type: LocalStocktakeService.getDefaultedStocktakeType(availableStocktakeTypes, undefined),
      stocktakeDate: "",
      stocktakerName: "",
      stocktakerPosition: "",
      subject: "",
    },
  });

  const { errors } = formState;

  const handleValidatedFormData = useCallback(
    (validatedFormData: LocalStocktakeSetupForm) => {
      if (isPending) {
        return;
      }

      resetForm(validatedFormData);

      onValidatedFormSubmit(validatedFormData);
    },
    [isPending, onValidatedFormSubmit, resetForm]
  );

  const handleValidFormSubmit: SubmitHandler<LocalStocktakeSetupForm> = useCallback(
    (formData) => {
      allowOnlineOnly(() => {
        handleValidatedFormData(formData);
      });
    },
    [allowOnlineOnly, handleValidatedFormData]
  );

  const handleInvalidFormSubmit: SubmitErrorHandler<LocalStocktakeSetupForm> = useCallback(
    (errors) => {
      const stocktakeType = getValues("type");
      if (isDraftsLimitExceeded(stocktakeType)) {
        openDraftsLimitExceededModal();
      }

      setToastMessage({
        type: "failure",
        message: "Your form contains some errors. Please fix them and try again.",
      });
      // May come in handy if user gets stuck
      console.error("Your form contains the following errors:", errors);
    },
    [getValues, isDraftsLimitExceeded, setToastMessage, openDraftsLimitExceededModal]
  );

  const chosenStocktakeTypeFormatted = LocalStocktakeService.getStocktakeTypeLabel(
    getValues("type")
  );

  return (
    <>
      <AllowOnlineOnlyWarningModal />
      <Modal isOpen={isDraftsLimitExceededModalOpen} closeModal={closeDraftsLimitExceededModal}>
        <StocktakeDraftsLimitExceededModal
          hasMultipleStocktakeTypes={canRenderStocktakeTypes}
          onConfirm={goToStocktakeOverview}
          closeModal={closeDraftsLimitExceededModal}
          stocktakeTypeFormatted={chosenStocktakeTypeFormatted}
        />
      </Modal>
      <form onSubmit={handleSubmit(handleValidFormSubmit, handleInvalidFormSubmit)} noValidate>
        <Heading size="200">Stocktake Details</Heading>
        <div className="w-full grid grid-cols-2 gap-6 mt-5">
          {canRenderStocktakeTypes && (
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Select
                  {...rest}
                  label="Type"
                  options={stocktakeTypeOptions}
                  value={stocktakeTypeOptions.find((option) => option.value === value)}
                  onChange={(selectedValue) => onChange(selectedValue.value)}
                  placeholder="Select Type"
                  errorMessage={errors.type?.message}
                />
              )}
            />
          )}
          <Controller
            name="stocktakeDate"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <DatePicker
                label="Date"
                additionalProps={rest}
                onChange={onChange}
                error={
                  errors.stocktakeDate?.message && (
                    <Paragraph size="200" color="text-dangerDefault">
                      {errors.stocktakeDate?.message}
                    </Paragraph>
                  )
                }
                placeholder="Set the date of the stocktake"
              />
            )}
          />
        </div>
        <div className="w-full grid grid-cols-2 gap-6 mt-5.5">
          <Controller
            name="stocktakerName"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Input
                {...rest}
                onChange={onChange}
                label="Name"
                withBorder
                errorMessage={errors.stocktakerName?.message}
              />
            )}
          />
          <Controller
            name="stocktakerPosition"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Input
                {...rest}
                onChange={onChange}
                label="Position"
                withBorder
                errorMessage={errors.stocktakerPosition?.message}
              />
            )}
          />
        </div>
        <div className="mt-5.5">
          <Controller
            name="subject"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Input
                {...rest}
                onChange={onChange}
                label="Subject"
                withBorder
                errorMessage={errors.subject?.message}
              />
            )}
          />
        </div>
        <RegularButton
          className="mt-6.5"
          type="submit"
          size="large"
          variant="primary"
          label="Start Stocktake"
          loading={isPending}
        />
      </form>
    </>
  );
};
