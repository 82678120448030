import { useQuery } from "@tanstack/react-query";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { configurationStore } from "src/objectStorage";
import { HomeService, LiteConfiguration } from "src/typegens";

import { usePunchOutIntegration } from "./usePunchOutIntegration";

export const CONFIGURATION_QUERY_KEY_BASE = "configuration";

const useConfigurationQuery = () => {
  const [{ configuration, vesselToken }, dispatch] = useAppStateContext();
  const { setOfflineCapabilitiesState, setManualNetworkState } = useOfflineCapabilities();
  const { setOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { punchoutSessionId } = usePunchOutIntegration();

  return useQuery<LiteConfiguration>({
    queryKey: [CONFIGURATION_QUERY_KEY_BASE],
    queryFn: async ({ signal }) => {
      const config = await HomeService.index(
        { ...(punchoutSessionId && { punchoutSessionId }) },
        { signal }
      );

      if (configuration === undefined) {
        dispatch({
          type: "setConfiguration",
          value: config,
        });
        setOfflineCapabilitiesState(config.fleet.allow.offline);
        setOfflineDraftEnabled(config.fleet.allow.offlineDraft);
        setManualNetworkState(config.fleet.allow.manualNetworkState);

        // Clear old entry in IDB
        await configurationStore.clear();

        if (config.fleet.allow.offline && vesselToken) {
          await configurationStore.put(config, vesselToken);
        }
      }

      return config;
    },
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });
};

export default useConfigurationQuery;
