import { OrderType } from "@web/common";

import { IDB_COMPOSITE_KEYS_SEPARATOR } from "../config/constants";

export const getCompositeStoredPortId = ({
  portId,
  orderType,
}: {
  portId?: string;
  orderType?: OrderType;
}) => {
  if (!portId) {
    return "";
  }

  return `${portId}${IDB_COMPOSITE_KEYS_SEPARATOR}${orderType || "DEFAULT"}`;
};
