import { useNavigate, useSearchParams } from "react-router-dom";

export type LeadTimeType = "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
export type SortingString = "PRICE_ASC" | "PRICE_DESC";
export interface SearchInput {
  query: string;
  sorting?: Sorting;
  priceMin?: string;
  priceMax?: string;
  leadTime?: LeadTimeType;
}

const AllSortOrder = ["asc", "desc"] as const;
export type SortOrder = typeof AllSortOrder[number];

const AllSortBy = ["price", "leadtime"] as const;
export type SortBy = typeof AllSortBy[number];

export interface Sorting {
  order: SortOrder;
  sortBy: SortBy;
}

export const extractSearchParams = (params: URLSearchParams): SearchInput => {
  const query = params.get("query") || "";
  const sortParam = params.get("sortBy") || "";
  const sortBy = (AllSortBy as ReadonlyArray<string>).includes(sortParam)
    ? (sortParam as SortBy)
    : undefined;
  const orderParam = params.get("order") || "";
  const order = (AllSortOrder as ReadonlyArray<string>).includes(orderParam)
    ? (orderParam as SortOrder)
    : undefined;

  const leadTime = (params.get("leadTime") as LeadTimeType) || undefined;
  const priceMin = params.get("priceMin") || undefined;
  const priceMax = params.get("priceMax") || undefined;
  return {
    query,
    leadTime,
    priceMin,
    priceMax,
    ...(sortBy && order && { sorting: { sortBy, order } }),
  };
};

export const useSearchInput = (): SearchInput => {
  const [params] = useSearchParams();
  return extractSearchParams(params);
};

export const toQueryString = (input: SearchInput): string => {
  const params = new URLSearchParams();
  params.set("query", input.query);
  input.sorting && params.set("sortBy", input.sorting.sortBy);
  input.sorting && params.set("order", input.sorting.order);
  input.leadTime && params.set("leadTime", input.leadTime);
  input.priceMin !== undefined && params.set("priceMin", `${input.priceMin}`);
  input.priceMax !== undefined && params.set("priceMax", `${input.priceMax}`);
  return params.toString();
};

export const getSortingString = (sorting?: Sorting): SortingString | undefined => {
  return sorting ? (sorting.order === "asc" ? "PRICE_ASC" : "PRICE_DESC") : undefined;
};

export const useSearchHelpers = (searchRoute: string) => {
  const navigate = useNavigate();
  const searchInput = useSearchInput();
  const { query, sorting, priceMin, priceMax, leadTime } = searchInput;

  const setQuery = (newQuery: string) =>
    navigate(`${searchRoute}?${toQueryString({ ...searchInput, query: newQuery })}`);

  const setSorting = (newSorting?: Sorting) =>
    navigate(`?${toQueryString({ ...searchInput, sorting: newSorting })}`);

  const setLeadTime = (leadTime: LeadTimeType) =>
    navigate(`?${toQueryString({ ...searchInput, leadTime })}`);

  const setPriceMin = (priceMin: string) =>
    navigate(`?${toQueryString({ ...searchInput, priceMin })}`);

  const setPriceMax = (priceMax: string) =>
    navigate(`?${toQueryString({ ...searchInput, priceMax })}`);

  return {
    query,
    sorting,
    leadTime,
    priceMin,
    priceMax,
    setQuery,
    setSorting,
    setLeadTime,
    setPriceMin,
    setPriceMax,
  };
};

export const useSearchSortingHelper = () => {
  const navigate = useNavigate();
  const searchInput = useSearchInput();
  const { query, sorting, priceMin, priceMax, leadTime } = searchInput;

  const setSorting = (newSorting?: Sorting) =>
    navigate(`?${toQueryString({ ...searchInput, sorting: newSorting })}`);

  const setLeadTime = (leadTime: LeadTimeType) =>
    navigate(`?${toQueryString({ ...searchInput, leadTime })}`);

  const setPriceMin = (priceMin: string) =>
    navigate(`?${toQueryString({ ...searchInput, priceMin })}`);

  const setPriceMax = (priceMax: string) =>
    navigate(`?${toQueryString({ ...searchInput, priceMax })}`);

  return {
    query,
    sorting,
    leadTime,
    priceMin,
    priceMax,
    setSorting,
    setLeadTime,
    setPriceMin,
    setPriceMax,
  };
};

export const useSearchQueryHelper = (searchRoute: string) => {
  const searchInput = useSearchInput();
  const navigate = useNavigate();
  const setQuery = (newQuery: string) =>
    navigate(`${searchRoute}?${toQueryString({ ...searchInput, query: newQuery })}`);

  return { query: searchInput.query, setQuery };
};
