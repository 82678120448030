import { ChangeEvent, KeyboardEvent, forwardRef, memo, useCallback } from "react";
import { NumericFormatProps } from "react-number-format/types/types";

import { AmountField as UiAmountField } from "@web/ui";
import { WithRequiredProperty, preprocessFormAmountValue } from "@web/utils";

import { ProductTileTextInput } from "../ProductTileTextInput";
import { useFormFieldKeyboardEvents } from "../hooks";

type Props = {
  className?: string;
  calculateNewValue: (event: KeyboardEvent<HTMLInputElement>) => number;
  setNewValue: (newValue: number | undefined) => void;
  minValue: number;
  maxValue: number;
} & WithRequiredProperty<NumericFormatProps, "aria-label">;

export const ProductTileAmountField = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      {
        className = "",
        "aria-label": ariaLabel,
        calculateNewValue,
        setNewValue,
        minValue,
        maxValue,
        ...props
      },
      // We need to catch `ref`, so we don't get errors in runtime
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      ref
    ) => {
      const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const newValue = preprocessFormAmountValue(event.currentTarget.value);
          setNewValue(newValue);
        },
        [setNewValue]
      );

      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLInputElement>({
        calculateNewValue,
        setNewValue,
      });

      return (
        <UiAmountField
          {...props}
          onChange={handleOnChange}
          className={className}
          isAllowed={({ floatValue }) =>
            !floatValue || (floatValue > minValue && floatValue <= maxValue)
          }
          allowNegative={false}
          inputMode="numeric"
          pattern="[0-9]*"
          InputComponent={ProductTileTextInput}
          onKeyDown={handleKeyDown}
          aria-label={ariaLabel}
        />
      );
    }
  )
);
ProductTileAmountField.displayName = "ProductTileAmountField";
