import { ArchiveIcon, DownloadIcon, XCircleIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  DeleteOrderDraftModal,
  OrderChanges,
  ProductItemsTable,
  RfqLineItem,
  RfqTotalPriceInfo,
} from "@web/common/components";
import { OrderContextMenuActions, useModalContext } from "@web/common/contexts/ModalContext";
import { OrderRequisition } from "@web/common/network";
import { OrderReqService } from "@web/common/services/OrderRequisition";
import {
  getBadgeSettingsFromOrderType,
  getOrderDate,
  shouldRenderOrderType,
  trackingCategories,
  trackingEvents,
} from "@web/common/utils";
import {
  ActionBar,
  Badge,
  DividerDropdownItem,
  DropdownItem,
  Heading,
  Label,
  Modal,
  Paragraph,
  RegularDropdownItem,
  TabItem,
  Tabs,
} from "@web/ui";
import { LEGACY_formatDate } from "@web/utils";

import { AttentionInfo } from "src/components/AttentionInfo";
import { HeaderAttachments } from "src/components/HeaderAttachments/HeaderAttachments";
import { OrderDraftSavedInfo } from "src/components/OrderDraftInfo";
import { ProductDetailsModal } from "src/components/ProductDetailsModal";
import { RoutesConfig, getOrdersOverviewPath } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { LEGACY_useDeleteOrderDraftMutation } from "src/hooks/LEGACY_useDeleteOrderDraftMutation";
import { useDeleteOrderDraftMutation } from "src/hooks/orderDrafts/useDeleteOrderDraftMutation";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { OrderLayout } from "src/layouts";
import { getBadgeSettingsFromOrderStatus } from "src/pages/OrdersOverview/utils";
import { LocalConfigurationService } from "src/services/LocalConfigurationService";
import { LocalProductService } from "src/services/LocalProductService";
import { LiteProductSku } from "src/typegens";

import { CheckItemsAvailabilityModalController } from "./CheckItemsAvailabilityModalController";
import { LEGACY_CheckItemsAvailabilityModalController } from "./LEGACY_CheckItemsAvailabilityModalController";
import { LEGACY_ReorderModalController } from "./LEGACY_ReorderModalController";
import { ReorderModalController } from "./ReorderModalController";

interface Props {
  order: OrderRequisition;
  contextMenuActions?: OrderContextMenuActions;
  topbarButtons?: React.ReactNode;
}

/**
 * TODO #5641: Refactor the component so most of the logic is moved to the wrapping controllers
 * (OrderDetailsController & DraftDetailsController). This logic has been copied over to this
 * component temporarily when working on the new DSHeader feature. It can be refactored once
 * the DSHeader flag is removed from the codebase, and the offline draft is enabled for everyone
 * and the offlineDraft configuration is removed along old code (LEGACY_OrderDetails component specifically).
 */

export const OrderDetails: React.FC<Props> = ({ order, contextMenuActions = {} }) => {
  const navigate = useNavigate();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const { openModal, closeModal } = useModalContext();
  const [{ configuration }] = useAppStateContext();
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);

  const { mutate: LEGACY_deleteDraftMutation, isPending: LEGACY_isDeletingDraft } =
    LEGACY_useDeleteOrderDraftMutation({
      onSuccess: () => {
        setIsDeleteDraftModalOpen(false);
      },
      shouldNavigate: true,
    });

  const { mutate: deleteDraft, isPending: isDeletingDraft } = useDeleteOrderDraftMutation({
    hasSuccessMessage: true,
    hasErrorMessage: true,
    onSuccess: () => {
      setIsDeleteDraftModalOpen(false);
      navigate(RoutesConfig.order.overview);
    },
  });

  const handleReorderClick = useCallback(() => {
    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.REORDER_CLICK,
    });

    if (areOfflineCapabilitiesEnabled) {
      openModal(<ReorderModalController order={order} />);
      return;
    }

    allowOnlineOnly(() => {
      openModal(<LEGACY_ReorderModalController order={order} />);
    });
  }, [openModal, order, allowOnlineOnly, areOfflineCapabilitiesEnabled]);

  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();

  const handleContinueWithDraftClick = useCallback(() => {
    if (isOfflineDraftEnabled && areOfflineCapabilitiesEnabled) {
      openModal(<CheckItemsAvailabilityModalController draft={order} />);
      return;
    }

    allowOnlineOnly(() => {
      openModal(<LEGACY_CheckItemsAvailabilityModalController draft={order} />);
    });
  }, [isOfflineDraftEnabled, areOfflineCapabilitiesEnabled, openModal, allowOnlineOnly, order]);

  const onDeleteDraftConfirm = (draftId: string) => {
    if (isOfflineDraftEnabled) {
      deleteDraft(draftId);
      return;
    }

    allowOnlineOnly(() => {
      LEGACY_deleteDraftMutation(draftId);
    });
  };

  const openDeleteDraftModal = () => {
    if (isOfflineDraftEnabled) {
      setIsDeleteDraftModalOpen(true);
      return;
    }

    allowOnlineOnly(() => {
      setIsDeleteDraftModalOpen(true);
    });
  };

  const { t } = useTranslation();

  // NOTE: it's only here because OrdersOverview table is not using links
  // when links are used then browser automatically scrolls to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isWaitingForQuote = ["SUPPLIER_QUOTE_ACKNOWLEDGED", "SUPPLIER_QUOTE_PENDING"].some(
    (value) => order.status === value
  );

  const hasOrderChanges = order.changes && order.changes?.length > 0;

  const orderTotalGrossAmount = OrderReqService.getTotalGrossAmount(order);
  const { attachments = [] } = order;
  const isDraft = order.type === "DRAFT";
  const { canClose, canDownloadMTML, canDownloadExcel, canDeleteDraft, canCancel } = order;
  const isExcelDownloadAvailable = (!isDraft || isOfflineDraftEnabled) && canDownloadExcel;
  const isCloseAttributeEnabled = useFlag("close-attribute");

  const isOrderCancellationEnabled = useFlag("order-cancellation");
  const areDigitalAssetsEnabled = useFlag("digital-asset");
  const isOrderNameEnabled = useFlag("order-name");
  const isRfq = order.isRfqRequisition;
  const isReorderAllowed = !!configuration?.fleet.allow.reorder;
  const isReorderEnabled = isReorderAllowed && !isRfq;
  const isOrderCancellationAvailable = canCancel && isOrderCancellationEnabled;

  const [appState] = useAppStateContext();
  const orderTypes = appState.configuration?.orderTypes;

  const orderDate = getOrderDate(order);

  const productItems = useMemo(
    () => order.items.map((item) => LocalProductService.convertOrderCatalogItemToProductItem(item)),
    [order.items]
  );

  const unchangedProductItems = useMemo(
    () =>
      order.unchangedItems.map((item) =>
        LocalProductService.convertOrderCatalogItemToProductItem(item)
      ),
    [order.unchangedItems]
  );

  const isAnyActionAvailable =
    isExcelDownloadAvailable ||
    canDeleteDraft ||
    canDownloadMTML ||
    canClose ||
    isOrderCancellationAvailable;

  // Only show divider if there are any `danger` actions available, and there is at least one `non-danger` action available
  const isDangerZoneDividerVisible =
    (isOrderCancellationAvailable || canDeleteDraft) &&
    (isExcelDownloadAvailable || canDownloadMTML || canClose);

  const contextMenuItems: DropdownItem[] = [
    ...(isExcelDownloadAvailable
      ? [
          {
            key: "downloadAsXlsx",
            renderComponent: () => (
              <RegularDropdownItem
                label="Download PO as XLSX"
                variant="basic"
                LeadingIcon={DownloadIcon}
                onClick={
                  contextMenuActions?.onExcelDownload
                    ? contextMenuActions?.onExcelDownload
                    : () => null
                }
              />
            ),
          },
        ]
      : []),
    ...(canDownloadMTML
      ? [
          {
            key: "downloadAsMtml",
            renderComponent: () => (
              <RegularDropdownItem
                label="Download PO as MTML"
                variant="basic"
                LeadingIcon={DownloadIcon}
                onClick={
                  contextMenuActions?.onMTMLDownload
                    ? contextMenuActions?.onMTMLDownload
                    : () => null
                }
              />
            ),
          },
        ]
      : []),
    ...(canClose
      ? [
          {
            key: "markAsClosed",
            renderComponent: () => (
              <RegularDropdownItem
                label={isCloseAttributeEnabled ? "Close Order" : "Mark Order as Closed"}
                variant="basic"
                LeadingIcon={ArchiveIcon}
                onClick={
                  contextMenuActions?.openModalCloseOrder
                    ? contextMenuActions?.openModalCloseOrder
                    : () => null
                }
                data-testid="closeOrderButton"
              />
            ),
          },
        ]
      : []),
    ...(isDangerZoneDividerVisible
      ? [
          {
            key: "dangerZoneDivider",
            renderComponent: () => <DividerDropdownItem />,
          },
        ]
      : []),
    ...(isOrderCancellationAvailable
      ? [
          {
            key: "cancelOrder",
            renderComponent: () => (
              <RegularDropdownItem
                label="Cancel Order"
                variant="danger"
                LeadingIcon={XCircleIcon}
                onClick={
                  contextMenuActions?.openModalCancelOrder
                    ? contextMenuActions?.openModalCancelOrder
                    : () => null
                }
              />
            ),
          },
        ]
      : []),
    // TODO #9283: This case is only possible for `DSHeader:true` & `offlineDraft:false`
    // Remove this case when `offlineDraft` flag is removed
    ...(canDeleteDraft
      ? [
          {
            key: "deleteDraft",
            renderComponent: () => (
              <RegularDropdownItem
                label="Delete Draft"
                variant="danger"
                LeadingIcon={XCircleIcon}
                onClick={() => {
                  openDeleteDraftModal();
                }}
              />
            ),
          },
        ]
      : []),
  ];

  let actionBarPrimaryButtonSettings;
  if (isDraft) {
    actionBarPrimaryButtonSettings = {
      title: "Continue with Draft",
      onClick: handleContinueWithDraftClick,
      testId: "continueWithDraftButton",
    };
  } else if (isReorderEnabled) {
    actionBarPrimaryButtonSettings = {
      title: "Reorder Items",
      onClick: handleReorderClick,
      testId: "reorderButton",
    };
  }

  const openProductDetailsModal = useCallback(
    (productSku: LiteProductSku) => {
      if (!areDigitalAssetsEnabled) {
        return;
      }

      const catalogItem = order.items.find((item) => item.variantId === productSku.productId);

      if (!catalogItem) {
        return;
      }

      const product = LocalProductService.convertOrderCatalogItemToProduct(catalogItem);

      openModal(
        <ProductDetailsModal product={product} closeModal={closeModal} withSidebar={false} />
      );
    },
    [areDigitalAssetsEnabled, closeModal, openModal, order]
  );

  const renderAttentionInfo = useCallback(() => {
    if (!configuration) {
      return null;
    }

    const items =
      LocalConfigurationService.getConfiguredPort(configuration, order.port)?.attentionInfo || [];

    return items.length > 0 ? <AttentionInfo items={items} className="mt-4 mb-2" /> : null;
  }, [configuration, order.port]);

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        id: "FINAL_ITEMS",
        label: "Final Items",
        "data-testid": "finalItemsTabButton",
        children: (
          <ProductItemsTable
            productItems={productItems}
            className="mt-3"
            openProductDetailsModal={openProductDetailsModal}
          />
        ),
      },
      {
        id: "CHANGES",
        label: "Changes",
        "data-testid": "changesTabButton",
        children: (
          <OrderChanges
            allowedChangeTypes={order.changeTypeList}
            unchangedItems={unchangedProductItems}
            changedItems={order.changes ?? []}
          />
        ),
      },
    ],
    [
      openProductDetailsModal,
      order.changeTypeList,
      order.changes,
      productItems,
      unchangedProductItems,
    ]
  );

  const content = (
    <>
      {attachments.length > 0 && (
        <HeaderAttachments attachments={attachments} className={`flex flex-col`} />
      )}
      <div className="flex justify-between">
        {order.type === "ORDER" ? (
          <Heading size="200">{t("pages.orderDetails.orderedItems")}</Heading>
        ) : (
          <Heading size="200">{t("pages.orderDetails.requestedItems")}</Heading>
        )}

        {!isOrderNameEnabled && shouldRenderOrderType(orderTypes, order.orderType) && (
          <div className="mr-auto ml-4 flex items-center">
            <Badge
              {...getBadgeSettingsFromOrderType({ orderType: order.orderType, orderTypes })}
              size="s"
            />
          </div>
        )}
        {order.type === "QUOTATION" ? (
          <RfqTotalPriceInfo
            isWaitingForQuote={isWaitingForQuote}
            totalGrossAmount={orderTotalGrossAmount}
          />
        ) : (
          !order.isRfqRequisition && (
            <div className="flex items-center">
              <Label size="200" color="text-textIcon-blackSecondary" className="pr-4">
                {t("pages.orderDetails.totalGrossAmount")}
              </Label>
              <Heading size="300">{orderTotalGrossAmount}</Heading>
            </div>
          )
        )}
      </div>
      {order.type === "QUOTATION" && (
        <Paragraph size="300" color="text-textIcon-blackDisabled" className="pb-3 uppercase mt-6">
          {t("pages.orderDetails.offeredItems")}
        </Paragraph>
      )}
      {hasOrderChanges ? (
        <Tabs
          className="mt-3 pb-6"
          size="large"
          variant="line"
          items={tabItems}
          initialTabId="FINAL_ITEMS"
        />
      ) : (
        <ProductItemsTable
          productItems={productItems}
          className="mt-3"
          openProductDetailsModal={openProductDetailsModal}
        />
      )}
      {order.type === "QUOTATION" && !isWaitingForQuote && (
        <Paragraph size="300" color="text-textIcon-blackDisabled" className="pb-3 uppercase mt-6">
          {t("pages.orderDetails.requestedItems")}
        </Paragraph>
      )}
      {order.rfqItems?.map((item) => {
        return (
          <RfqLineItem isRemoved={false} key={item.id} quantity={item.quantity} rfqfItem={item} />
        );
      })}
    </>
  );

  return (
    <OrderLayout
      order={order}
      actionBar={
        <ActionBar
          primaryButtonSettings={actionBarPrimaryButtonSettings}
          dropdownSettings={isAnyActionAvailable ? { items: contextMenuItems } : undefined}
          backButtonSettings={{
            title: "Return to Overview",
            onClick: () => {
              if (isCloseAttributeEnabled) {
                if (order.closed) {
                  navigate(getOrdersOverviewPath("closed"));
                } else {
                  navigate(getOrdersOverviewPath("active"));
                }
                return;
              }

              navigate(RoutesConfig.order.overview);
            },
          }}
        />
      }
      renderAttentionInfo={renderAttentionInfo}
    >
      <div className="py-6">
        <Heading size="100" className="flex gap-2 flex-wrap" data-testid="orderDetails_title">
          {!isOrderNameEnabled ? (
            <>
              <span className="capitalize">{order.type.toLowerCase()}</span>
              {t(`status.${order.status}`).toUpperCase() !== order.type.toUpperCase() && (
                <span className="text-gray-500">({t(`status.${order.status}`)})</span>
              )}
            </>
          ) : (
            <span className="capitalize">{order.subject || "Untitled"}</span>
          )}
        </Heading>
        <div className="flex space-x-4 mt-2">
          {order.type === "DRAFT" && (
            <OrderDraftSavedInfo
              paragraphSize="100"
              textColor="text-textIcon-blackDisabled"
              isSavingDraft={false}
              draftLastUpdated={order.updatedAt.toISOString()}
              basketLastUpdated={order.updatedAt.getTime()}
            />
          )}
          {order.type !== "DRAFT" && !!orderDate && (
            <div className="text-gray-500">Order date: {LEGACY_formatDate(orderDate)}</div>
          )}
          {order.customerOrderId && (
            <div className="text-gray-500">Internal PO: {order.customerOrderId}</div>
          )}
        </div>
        {isOrderNameEnabled && (
          <div className="py-3 flex items-center" data-testid="orderDetails_statusBar">
            {order.orderType !== "DEFAULT" && (
              <>
                <Badge
                  {...getBadgeSettingsFromOrderType({
                    orderType: order.orderType,
                    orderTypes,
                    text: order.orderType,
                  })}
                  size="s"
                />
                &nbsp;
              </>
            )}
            <Badge
              withBullet
              text={getBadgeSettingsFromOrderStatus(order.status, t).text}
              color={getBadgeSettingsFromOrderStatus(order.status, t).color}
              size="m"
              contrast="low"
            />
          </div>
        )}
      </div>
      {content}
      <AllowOnlineOnlyWarningModal />
      <Modal isOpen={isDeleteDraftModalOpen} closeModal={() => setIsDeleteDraftModalOpen(false)}>
        <DeleteOrderDraftModal
          closeModal={() => setIsDeleteDraftModalOpen(false)}
          draftId={order.id}
          isLoading={isOfflineDraftEnabled ? isDeletingDraft : LEGACY_isDeletingDraft}
          onConfirm={() => onDeleteDraftConfirm(order.id)}
        />
      </Modal>
    </OrderLayout>
  );
};
