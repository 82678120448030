import { forwardRef, memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { UnitOfMeasure } from "@web/models";

import { LocalStocktakeReportForm, StocktakeReportItemMeasurementUnitFieldPath } from "src/models";

import { ProductTileUnitOfMeasureField } from "../../ProductTileUnitOfMeasureField";

type Props = {
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
  unitOfMeasures: UnitOfMeasure[];
};

export const UnitOfMeasureField = memo(
  forwardRef<HTMLButtonElement, Props>(
    (
      { measurementUnitFieldPath, unitOfMeasures },
      // We need to catch `ref`, so we don't get errors in runtime
      ref
    ) => {
      const { control, setValue } = useFormContext<LocalStocktakeReportForm>();

      const setFieldValue = useCallback(
        (newMeasurementUnitValue: string) => {
          setValue(measurementUnitFieldPath, newMeasurementUnitValue);
        },
        [measurementUnitFieldPath, setValue]
      );

      return (
        <Controller
          name={measurementUnitFieldPath}
          control={control}
          render={({ field: { value } }) => (
            <ProductTileUnitOfMeasureField
              ref={ref}
              placeholder="Select..."
              value={value}
              unitOfMeasures={unitOfMeasures}
              setNewValue={setFieldValue}
              aria-label="Unit of measure form select field (required)"
            />
          )}
        />
      );
    }
  )
);
UnitOfMeasureField.displayName = "UnitOfMeasureField";
