import Dexie from "dexie";

import { isDefined } from "@web/utils";

import { LocalLiteConfiguration } from "src/models";

import { StoreNames, getStoreName } from "../config";

// This is the previous model of the configuration object that is needed to be able to operate
// on previously cached configuration data during the upgrade.
type PreviousConfigModel = LocalLiteConfiguration & {
  vessel: LocalLiteConfiguration["vessel"] & { fleetId: string };
  permissions: LocalLiteConfiguration["fleet"]["permissions"];
  allow: LocalLiteConfiguration["fleet"]["allow"];
  basketForm?: LocalLiteConfiguration["basketAnswersForm"];
};

export class LocalConfigurationDatabase extends Dexie {
  configuration: Dexie.Table<LocalLiteConfiguration, string>;

  constructor() {
    super(getStoreName(StoreNames.CONFIGURATION));
    this.version(0.1).stores({
      configuration: "",
    });
    this.version(0.2)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config) => {
            if (!isDefined((config as PreviousConfigModel).allow.manualNetworkState)) {
              (config as PreviousConfigModel).allow.manualNetworkState = true;
            }
          });
      });
    this.version(0.3)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config) => {
            if (!isDefined((config as PreviousConfigModel).allow.sortPurchaseOrderLines)) {
              (config as PreviousConfigModel).allow.sortPurchaseOrderLines = false;
            }
          });
      });
    this.version(0.4)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config, ref) => {
            if (!config.fleet) {
              /**
               * This code:
               * - removes the `fleetId` from the vessel object and moves it to the fleet object.
               * - removes the `permissions` and `allow` from the configuration object and moves them to the fleet object.
               */

              const { allow, permissions, ...cleanConfig } = config as PreviousConfigModel;
              const { fleetId, ...cleanVessel } = (config as PreviousConfigModel).vessel;

              ref.value = {
                ...cleanConfig,
                vessel: cleanVessel,
                fleet: {
                  id: fleetId || "",
                  name: "",
                  permissions,
                  allow,
                },
              };
            }
          });
      });
    this.version(0.5)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config) => {
            if (!isDefined(config.fleet.allow.orderExtraItems)) {
              config.fleet.allow.orderExtraItems = false;
            }
          });
      });
    this.version(0.6)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config, ref) => {
            if (
              !isDefined(config.basketAnswersForm) &&
              isDefined((config as PreviousConfigModel).basketForm)
            ) {
              /**
               * This code:
               * - removes the `basketForm` from the configuration object and moves it to the new property `basketAnswersForm` (field rename)
               */
              const { basketForm, ...cleanConfig } = config as PreviousConfigModel;

              ref.value = {
                ...cleanConfig,
                basketAnswersForm: basketForm,
              };
            }
          });
      });
    this.version(0.7)
      .stores({
        configuration: "",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalLiteConfiguration>("configuration")
          .toCollection()
          .modify((config) => {
            if (!isDefined(config.orderTypeConfigs)) {
              config.orderTypeConfigs = [];
            }
          });
      });
  }
}

export const configurationStore = new LocalConfigurationDatabase().configuration;
