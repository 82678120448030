import { z } from "zod";

import { SkuAttachmentTypeSchema } from "./SkuAttachmentTypeSchema";
import { nonEmptyString } from "./common";

export const LiteSkuAttachmentSchema = z.object({
  type: SkuAttachmentTypeSchema,
  // At least `d.om`
  attachmentUrl: nonEmptyString({ minLength: 4 }),
  description: nonEmptyString(),
  fileSize: z.number().positive(),
  createdDate: z.string(),
  name: z.string().optional(),
});
