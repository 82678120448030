import { useNavigate } from "react-router-dom";

import { OrderItem, Port } from "@web/common";
import { SupplierInformation } from "@web/common/network";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { BasketEntry } from "src/contexts/BasketContext";
import { LocalLiteOrderExtraItem, OrderStocktakeType } from "src/models";
import { LiteDutyFreeDeclaration, LiteProductSku, LiteRfqItem } from "src/typegens";
import { getItemUnits, pairProductsWithVariants } from "src/utils";

import useBasket from "./useBasket";

type AddProductsToBasketProps = {
  catalogItems: OrderItem[];
  supplierInformation: SupplierInformation;
  port: Port;
  rfqItems?: LiteRfqItem[];
  extraItems: LocalLiteOrderExtraItem[];
  deliveryDate?: string;
  dutyFreeDeclaration?: LiteDutyFreeDeclaration;
  orderType?: OrderStocktakeType;
  subject?: string;
};

export const useAddProductsToBasket = ({
  catalogItems,
  port,
  deliveryDate,
  dutyFreeDeclaration,
  rfqItems,
  extraItems,
  orderType,
  subject,
}: AddProductsToBasketProps) => {
  const navigate = useNavigate();
  const [, dispatch] = useAppStateContext();
  const {
    addMultipleCatalogItemsToBasket,
    addMultipleRfqsToBasket,
    setBasketExtraItems,
    clearBasket,
  } = useBasket();

  const addProductsToBasket = (liteProductList: LiteProductSku[]) => {
    const availableProducts: BasketEntry[] = (
      pairProductsWithVariants(catalogItems)(liteProductList).filter((e) => e[1]) as Array<
        [OrderItem, LiteProductSku]
      >
    ).map(([product, variant]) => ({
      sku: variant,
      quantity: getItemUnits({ product, variant }),
    }));

    dispatch({
      type: "setPreconfigureOrderSetup",
      value: {
        port,
        deliveryDate,
        dutyFreeDeclaration,
        orderType,
        orderName: subject,
      },
    });

    clearBasket();
    addMultipleCatalogItemsToBasket(availableProducts);
    if (rfqItems) {
      addMultipleRfqsToBasket(rfqItems);
    }
    if (extraItems.length > 0) {
      setBasketExtraItems(extraItems);
    }
    navigate(RoutesConfig.basket);
  };

  return { addProductsToBasket };
};
