import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LitePunchoutBasket } from "src/typegens";

import { LitePortSchema } from "./LitePortSchema";
import { LitePunchoutAvailableItemSchema } from "./LitePunchoutAvailableItemSchema";
import { LitePunchoutItemSchema } from "./LitePunchoutItemSchema";
import { LitePunchoutUnavailableItemSchema } from "./LitePunchoutUnavailableItemSchema";

export const LitePunchoutBasketSchema = z.object({
  port: LitePortSchema.optional(),
  // @deprecated
  items: z.array(LitePunchoutItemSchema),
  availableItems: z.array(LitePunchoutAvailableItemSchema),
  unavailableItems: z.array(LitePunchoutUnavailableItemSchema),
});

export type LocalLitePunchoutBasket = z.infer<typeof LitePunchoutBasketSchema>;

assertTypeEquality<LocalLitePunchoutBasket, LitePunchoutBasket, LocalLitePunchoutBasket>();
