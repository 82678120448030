import classnames from "classnames";
import { InputHTMLAttributes, forwardRef } from "react";

export type Props = InputHTMLAttributes<HTMLInputElement>;

export const ProductTileTextInput = forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      type="text"
      className={classnames(
        className,
        "block w-full h-6.5 border rounded-lg focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white focus:ring-primaryDefault"
      )}
    />
  )
);
ProductTileTextInput.displayName = "ProductTileTextInput";
