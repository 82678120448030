import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useNetworkToggle } from "src/contexts/NetworkToggle";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";

export const useWasUnableToReconnect = () => {
  const { areOfflineCapabilitiesEnabled, isManualNetworkStateEnabled } = useOfflineCapabilities();
  const { isToggleOnline } = useNetworkToggle();
  const { isOffline, wentOfflineFromOnline } = useNetworkDetector();

  return {
    wasUnableToReconnect:
      areOfflineCapabilitiesEnabled &&
      isManualNetworkStateEnabled &&
      isToggleOnline &&
      !wentOfflineFromOnline &&
      isOffline,
  };
};
