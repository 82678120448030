import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteStocktakeReportListItem } from "src/typegens";

import { LiteStocktakeReportSchema } from "./LiteStocktakeReportSchema";

export const LiteStocktakeReportListItemSchema = LiteStocktakeReportSchema.pick({
  id: true,
  vesselId: true,
  type: true,
  inventoryCount: true,
  robValue: true,
  stocktakeDate: true,
  updatedAt: true,
  createdAt: true,
});

export type LocalLiteStocktakeReportListItem = z.infer<typeof LiteStocktakeReportListItemSchema>;

assertTypeEquality<
  LocalLiteStocktakeReportListItem,
  LiteStocktakeReportListItem,
  LocalLiteStocktakeReportListItem
>();
