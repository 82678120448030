import { KeyboardEvent } from "react";

import { isDefined } from "./isDefined";

type Params = {
  currentValue: number;
  event: KeyboardEvent<HTMLInputElement>;
  minValue?: number;
  maxValue?: number;
  postProcessValue?: (value: number) => number;
  baseModifier?: number;
  enableFractionModifiers?: boolean;
};

export const getNewAmountValueFromKeyboardEvent = ({
  currentValue,
  event,
  postProcessValue = (value: number) => value,
  minValue,
  maxValue,
  enableFractionModifiers = false,
}: Params): number => {
  let valueModifier = 1;

  if (enableFractionModifiers && event.shiftKey && event.altKey) {
    valueModifier = 0.01;
  } else if (enableFractionModifiers && event.altKey) {
    valueModifier = 0.1;
  } else if (event.shiftKey) {
    valueModifier = 10;
  }

  if (event.key === "ArrowRight") {
    const newValue = currentValue + valueModifier;
    if (isDefined(maxValue)) {
      return postProcessValue(newValue <= maxValue ? newValue : maxValue);
    }
    return postProcessValue(newValue);
  }

  if (event.key === "ArrowLeft") {
    const newValue = currentValue - valueModifier;
    if (isDefined(minValue)) {
      return postProcessValue(newValue >= minValue ? newValue : minValue);
    }
    return postProcessValue(newValue);
  }

  return currentValue;
};
