import { S2SHeaderLogo, S2SLogo } from "../../../icons";
import { Heading, Paragraph, RegularButton } from "../../atoms";

type Props = {
  buttonText?: string;
  onButtonClick?: () => void;
  title?: string;
  message?: string | React.ReactNode;
};

export const EmptyCatalogErrorPage = ({
  buttonText = "Return to Order List",
  title = "The catalog could not be loaded for this port",
  message,
  onButtonClick,
}: Props) => {
  return (
    <div className="h-screen" data-testid="unauthorizedErrorPage">
      <div className="flex justify-start">
        <S2SHeaderLogo className="[&>path]:fill-[#022044]" />
      </div>
      <div className="h-full flex justify-center items-center">
        <S2SLogo />
        <div className="w-0.5 h-[285px] mx-7 bg-textIcon-blackSecondary" role="presentation" />
        <div className="flex flex-col">
          <Heading
            size="100"
            className="mb-4"
            color="text-primaryPressed"
            data-testid="unauthorizedErrorPage_title"
          >
            {title}
          </Heading>
          <Paragraph size="100">
            {message ? (
              message
            ) : (
              <span>
                We are unable to load your catalog at this time for the selected port and supplier.
                <br />
                Please reach out to{" "}
                <a className="underline text-primaryHover" href="mailto:support@source2sea.com">
                  support@source2sea.com
                </a>
              </span>
            )}
          </Paragraph>
          {onButtonClick && (
            <RegularButton
              className="mt-[40px]"
              variant="primary"
              size="large"
              label={buttonText}
              width="content"
              onClick={onButtonClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};
