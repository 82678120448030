import _isNull from "lodash/isNull";

import { getDefaultedOrderTypeAccountingForConfigurationLogic } from "@web/common";
import { isDefined } from "@web/utils";

import { LocalConfigurationService } from "src/services/LocalConfigurationService";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";

import { AppState, AppStateAction } from "./models";

export const defaultAppState: AppState = {
  products: {},
  systemMessages: [],
  vesselToken: undefined,
  isPunchoutSession: false,
  lastGatherExpCategoryId: "",
};

const getForcePreconfigureOrderSetupValue = ({
  port,
  orderType,
  isPunchoutSession,
}: {
  port: AppState["port"] | undefined;
  orderType: AppState["orderType"] | undefined;
  isPunchoutSession: AppState["isPunchoutSession"];
}): boolean => (isPunchoutSession ? !port : !port || !orderType);

export const appStateReducer = (state: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case "setAppState":
      return { ...state, ...action.value };

    case "setConfiguration": {
      if (!action.value) {
        return state;
      }

      return {
        ...state,
        configuration: {
          ...action.value,
          orderTypes: action.value.orderTypes ?? [],
          vessel: LocalOrderReqService.toVessel(action.value.vessel),
          ports: LocalConfigurationService.toPorts(
            action.value.ports,
            action.value.portConfiguration,
            action.value.attentionInfo
          ),
          canCreateOrder: LocalConfigurationService.canCreateOrder(action.value),
          canCreateRequisition: LocalConfigurationService.canCreateRequisition(action.value),
          isAutoApprovedRequisition: LocalConfigurationService.isAutoApprovedRequisition(
            action.value
          ),
        },
        // `undefined` passed consciously to trigger the default order type selection
        orderType: getDefaultedOrderTypeAccountingForConfigurationLogic(
          action.value.orderTypes,
          state.orderType || undefined
        ),
      };
    }

    case "setPreconfigureOrderSetup": {
      const { port, deliveryDate, dutyFreeDeclaration, orderType, orderName } = action.value;
      const calculatedOrderType = getDefaultedOrderTypeAccountingForConfigurationLogic(
        state.configuration?.orderTypes,
        orderType
      );
      const calculatedPort =
        state.configuration && port
          ? LocalConfigurationService.getConfiguredPort(state.configuration, port)
          : undefined;
      return {
        ...state,
        port: calculatedPort,
        deliveryDate,
        dutyFreeDeclaration:
          isDefined(dutyFreeDeclaration?.dutyFree) && !_isNull(dutyFreeDeclaration?.dutyFree)
            ? dutyFreeDeclaration
            : undefined,
        orderType: calculatedOrderType,
        orderName,
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: calculatedPort,
          orderType: calculatedOrderType,
          isPunchoutSession: state.isPunchoutSession,
        }),
      };
    }

    case "setPort": {
      const calculatedPort = state.configuration
        ? LocalConfigurationService.getConfiguredPort(state.configuration, action.value)
        : undefined;
      return {
        ...state,
        // Treat `state.configuration` as a source of truth for ports
        port: calculatedPort,
        products: {},
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: calculatedPort,
          orderType: state.orderType,
          isPunchoutSession: state.isPunchoutSession,
        }),
      };
    }

    case "clearPort":
      return {
        ...state,
        port: undefined,
        products: {},
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: undefined,
          orderType: state.orderType,
          isPunchoutSession: state.isPunchoutSession,
        }),
      };

    case "clearDeliveryDate":
      return {
        ...state,
        deliveryDate: undefined,
        products: {},
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: state.port,
          orderType: state.orderType,
          isPunchoutSession: state.isPunchoutSession,
        }),
      };

    case "clearDutyFreeDeclaration":
      return {
        ...state,
        dutyFreeDeclaration: undefined,
        products: {},
      };

    case "setOrderType": {
      const calculatedOrderType = getDefaultedOrderTypeAccountingForConfigurationLogic(
        state.configuration?.orderTypes,
        action.value
      );
      return {
        ...state,
        // `undefined` passed consciously to trigger the default order type selection
        orderType: calculatedOrderType,
        products: {},
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: state.port,
          orderType: calculatedOrderType,
          isPunchoutSession: state.isPunchoutSession,
        }),
      };
    }

    case "clearOrderType": {
      const calculatedOrderType = getDefaultedOrderTypeAccountingForConfigurationLogic(
        state.configuration?.orderTypes,
        undefined
      );
      return {
        ...state,
        // `undefined` passed consciously to trigger the default order type selection
        orderType: calculatedOrderType,
        products: {},
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: state.port,
          orderType: calculatedOrderType,
          isPunchoutSession: state.isPunchoutSession,
        }),
      };
    }

    case "clearOrderName": {
      return {
        ...state,
        orderName: undefined,
      };
    }

    case "setSystemMessage":
      return {
        ...state,
        systemMessages: [...state.systemMessages, action.value],
      };

    case "clearSystemMessage":
      return {
        ...state,
        systemMessages: state.systemMessages.filter((msg) => msg.id !== action.value.id),
      };

    case "setVesselToken":
      return {
        ...state,
        vesselToken: action.value,
      };

    case "cacheProducts": {
      const newProducts = action.value.products.reduce((accumulator, product) => {
        return {
          ...accumulator,
          [product.id]: product,
        };
      }, {});
      return {
        ...state,
        products: {
          ...state.products,
          ...newProducts,
        },
      };
    }

    case "setLastCreated": {
      return {
        ...state,
        lastCreatedOrderRequisitions: (action.value ?? []).map((order) =>
          LocalOrderReqService.toOrderRequisition(order)
        ),
      };
    }

    case "forcePreconfigureOrderSetup": {
      return {
        ...state,
        forcePreconfigureOrderSetup: action.value,
      };
    }

    case "setOfflineDraftEnabled": {
      return {
        ...state,
        isOfflineDraftEnabled: action.value,
      };
    }

    case "setAppArea": {
      return {
        ...state,
        appArea: action.value,
      };
    }

    case "setIsPunchoutSession": {
      return {
        ...state,
        isPunchoutSession: action.value,
        forcePreconfigureOrderSetup: getForcePreconfigureOrderSetupValue({
          port: state.port,
          orderType: state.orderType,
          isPunchoutSession: action.value,
        }),
      };
    }

    case "setOrderName": {
      return {
        ...state,
        orderName: action.value,
      };
    }

    case "setLastGatherExpCategoryId": {
      return {
        ...state,
        lastGatherExpCategoryId: action.value,
      };
    }
    case "clearLastGatherExpCategoryId": {
      return {
        ...state,
        lastGatherExpCategoryId: "",
      };
    }

    default:
      return state;
  }
};
