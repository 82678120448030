import { DEFAULT_ORDER_TYPE } from "@web/common";

import { useAppStateContext } from "../contexts/AppStateContext";
import useBasket from "./useBasket";

export const LEGACY_useOrderDraft = () => {
  const [{ configuration, port, dutyFreeDeclaration, deliveryDate, orderType, orderName }] =
    useAppStateContext();
  const { rfqItems, getOrderItems, lineItems } = useBasket();
  // TODO #11962/11951: Get supplier id from the app state instead of line item
  const supplierId = lineItems[0]?.sku.supplierInformation.supplierId;

  const getOrderDraftData = () => {
    if (!configuration) {
      throw new Error("No configuration");
    }
    if (!port) {
      throw new Error("No port selected");
    }
    if (!supplierId) {
      throw new Error("Unknown supplier");
    }

    return {
      dutyFreeDeclaration,
      requesterInformation: {
        email: configuration?.vessel.contactInformation?.email || "",
        name: configuration?.vessel.contactInformation?.name || "",
      },
      portId: port?.id,
      vesselId: configuration.vessel.id,
      rfqItems,
      catalogItems: getOrderItems(),
      supplierId,
      deliveryDate,
      orderType: orderType ?? DEFAULT_ORDER_TYPE,
      subject: orderName,
    };
  };

  return {
    getOrderDraftData,
  };
};
