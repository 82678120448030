import Dexie from "dexie";

import { LocalLiteOfflineCategory, LocalLiteProduct, LocalOfflineCatalogByPort } from "src/models";

import { IDB_COMPOSITE_KEYS_SEPARATOR } from "../../config/constants";
import { getCompositeStoredPortId } from "../../utils";

type ProductsByPort = { productIds: string[]; portId: string };

const defaultOrderType = "DEFAULT";

export class S2SDatabaseFactory extends Dexie {
  categories: Dexie.Table<LocalLiteOfflineCategory, string>;
  categoriesByPortId: Dexie.Table<LocalOfflineCatalogByPort, string>;
  products: Dexie.Table<LocalLiteProduct, string>;
  productsByPortId: Dexie.Table<ProductsByPort, string>;

  constructor() {
    super("s2s-lite");
    this.version(0.8).stores({
      categories: "categoryPortId",
      categoriesByPortId: "portId",
      drafts: "id,vessel.id",
      products: "id",
      productsByPortId: "portId",
    });
    this.version(0.9).stores({
      categories: "categoryPortId",
      categoriesByPortId: "portId",
      // This deletes unused `drafts` table, in the next db version this property should not be included
      drafts: null,
      products: "id",
      productsByPortId: "portId",
    });
    this.version(1.0)
      .stores({
        // Dexie does not support changing primary keys,
        // instead of creating new tables the following primary keys
        // became composite:
        // `categoryPortId` in `categories` table: ${categoryId}__${portId}__${orderType};
        // `portId` in `categoriesByPortId` table: ${portId}__${orderType}
        categories: "categoryPortId, categoryId, portId, orderType",
        categoriesByPortId: "portId, orderType",
        products: "id",
        productsByPortId: "portId",
      })
      .upgrade((trans) => {
        trans
          .table<LocalLiteOfflineCategory>("categories")
          .toCollection()
          .modify((category: LocalLiteOfflineCategory) => {
            const [categoryId, portId] = category.categoryPortId.split(
              IDB_COMPOSITE_KEYS_SEPARATOR
            );
            category.categoryPortId = category.categoryPortId.concat(
              `${IDB_COMPOSITE_KEYS_SEPARATOR}${defaultOrderType}`
            );
            category.categoryId = categoryId;
            category.portId = portId;
            category.orderType = defaultOrderType;
          });

        trans
          .table<LocalOfflineCatalogByPort>("categoriesByPortId")
          .toCollection()
          .modify((catalog) => {
            catalog.portId = getCompositeStoredPortId({
              portId: catalog.portId,
              orderType: defaultOrderType,
            });
            catalog.orderType = defaultOrderType;
          });
      });
    this.version(1.1)
      .stores({
        // Dexie does not support changing primary keys.
        // In this migration the following key will become composite:
        // `portId` in `productsByPortId` table: ${portId}__${orderType}
        categories: "categoryPortId, categoryId, portId, orderType",
        categoriesByPortId: "portId, orderType",
        products: "id",
        productsByPortId: "portId",
      })
      .upgrade((trans) => {
        trans
          .table<ProductsByPort>("productsByPortId")
          .toCollection()
          .modify((productsObject) => {
            productsObject.portId = getCompositeStoredPortId({
              portId: productsObject.portId,
              orderType: defaultOrderType,
            });
          });
      });
  }
}

export const s2sDatabase = new S2SDatabaseFactory();
