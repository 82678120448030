import {
  DefaultError,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useMemo } from "react";

import { OrderType } from "@web/common";
import {
  LeadTimeType,
  SortingString,
  getSortingString,
  useSearchInput,
} from "@web/common/hooks/useSearchHelpers";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { ApiListLiteProduct, CatalogSearchService } from "src/typegens";

import { usePunchOutIntegration } from "./usePunchOutIntegration";

const PRODUCTS_SEARCH_QUERY_KEY_BASE = "productsSearch";

type GetQueryKeyArgs = {
  query: string;
  portId: string;
  supplierId: string;
  sort: SortingString | undefined;
  priceMax: string | undefined;
  priceMin: string | undefined;
  leadTime: LeadTimeType | undefined;
  orderType: OrderType | undefined;
};

const getQueryKey = ({
  query,
  portId,
  supplierId,
  sort,
  priceMax,
  priceMin,
  leadTime,
  orderType,
}: GetQueryKeyArgs) => [
  PRODUCTS_SEARCH_QUERY_KEY_BASE,
  query,
  portId,
  supplierId,
  sort,
  priceMax,
  priceMin,
  leadTime,
  orderType,
];

type UseProductsSearchQuery = UseInfiniteQueryResult<InfiniteData<ApiListLiteProduct>>;

export const useProductsSearchQuery = (): UseProductsSearchQuery => {
  const [{ port, orderType }] = useAppStateContext();
  const { query, sorting, priceMin, priceMax, leadTime } = useSearchInput();
  const { supplierId } = usePunchOutIntegration();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();

  const portId = port?.id || "";
  // supplierId is allowed to be empty
  const defaultedSupplierId = supplierId || "";
  const sort = getSortingString(sorting);

  const queryKey = useMemo(
    () =>
      getQueryKey({
        query,
        portId,
        supplierId: defaultedSupplierId,
        sort,
        priceMax,
        priceMin,
        leadTime,
        orderType,
      }),
    [query, portId, defaultedSupplierId, sort, priceMax, priceMin, leadTime, orderType]
  );

  return useInfiniteQuery<
    ApiListLiteProduct,
    DefaultError,
    InfiniteData<ApiListLiteProduct>,
    QueryKey,
    string
  >({
    queryKey,
    queryFn: ({ pageParam, signal }) => {
      return CatalogSearchService.searchProducts(
        {
          portId,
          query,
          sort,
          priceMin,
          priceMax,
          leadTime,
          cursor: pageParam,
          supplierId: defaultedSupplierId,
          orderType,
        },
        { signal }
      );
    },
    enabled: !!portId && !!orderType && (areOfflineCapabilitiesEnabled ? isOnline : true),
    initialPageParam: "",
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
  });
};
