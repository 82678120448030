export class OfflineCatalogError extends Error {
  public readonly body: unknown;
  public readonly status: number;

  constructor(message: string, status: number, body?: unknown) {
    super(message);
    this.name = "OfflineCatalogError";
    this.status = status;
    this.body = body;
  }
}
