import { useReducer } from "react";

import { NETWORK_TOGGLE_MODE_STORAGE_KEY } from "src/config/constants";

import { NetworkDetectorState, NetworkDetectorStateAction } from "./models";

export const defaultNetworkState: NetworkDetectorState = {
  currentNetworkState: "unknown",
  forcedNetworkState:
    localStorage.getItem(NETWORK_TOGGLE_MODE_STORAGE_KEY) === "offline" ? "offline" : undefined,
  isShakyConnection: false,
};

const NetworkDetectorReducer = (
  networkDetectorState: NetworkDetectorState,
  action: NetworkDetectorStateAction
): NetworkDetectorState => {
  switch (action.type) {
    case "setOnline":
      return {
        ...networkDetectorState,
        currentNetworkState: "online",
      };
    case "setOffline":
      return {
        ...networkDetectorState,
        currentNetworkState: "offline",
      };
    case "setReconnecting":
      return {
        ...networkDetectorState,
        currentNetworkState: "reconnecting",
      };
    case "setShakyConnection":
      return {
        ...networkDetectorState,
        isShakyConnection: true,
      };
    case "resetShakyConnection":
      return {
        ...networkDetectorState,
        isShakyConnection: false,
      };
    case "forceOffline":
      return {
        ...networkDetectorState,
        forcedNetworkState: "offline",
      };
    case "clearForcedNetworkState":
      return {
        ...networkDetectorState,
        forcedNetworkState: undefined,
      };
    case "setNavigatorOnline":
      return {
        ...networkDetectorState,
        isNavigatorOnline: action.value,
      };
    default:
      return networkDetectorState;
  }
};

export const useNetworkDetectorReducer = () =>
  useReducer(NetworkDetectorReducer, defaultNetworkState);
