import { z } from "zod";

import { LiteInvoiceAccountSchema } from "./LiteInvoiceAccountSchema";
import { VesselContactInformationSchema } from "./VesselContactInformationSchema";

export const LiteVesselSchema = z.object({
  id: z.string(),
  name: z.string(),
  contactInformation: VesselContactInformationSchema,
  invoiceAccounts: z.array(LiteInvoiceAccountSchema),
});
