import { z } from "zod";

import { ApiMoneySchema } from "./ApiMoneySchema";
import { LiteProductSkuSchema } from "./LiteProductSkuSchema";

export const LitePunchoutItemSchema = z.object({
  name: z.string(),
  quantity: z.number(),
  unitPrice: ApiMoneySchema,
  details: LiteProductSkuSchema.optional(),
  // @deprecated
  itemId: z.string(),
  // @deprecated
  displayUnit: z.string(),
  // @deprecated
  supplierItemId: z.string().optional(),
  // @deprecated
  impaCode: z.string().optional(),
});

export type LocalLitePunchoutItem = z.infer<typeof LitePunchoutItemSchema>;
