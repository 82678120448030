import { z } from "zod";

import { LiteAgentInformationSchema } from "./LiteAgentInformationSchema";
import { LiteAttentionInfoFleetsConfigEntrySchema } from "./LiteAttentionInfoFleetsConfigEntrySchema";

export const LiteSupplierConfigurationSchema = z.object({
  id: z.string(),
  name: z.string(),
  dutyFree: z.boolean(),
  freeDelivery: z.boolean(),
  agentInformation: LiteAgentInformationSchema.optional(),
  attentionInfo: z.array(LiteAttentionInfoFleetsConfigEntrySchema),
});
