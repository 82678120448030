import { KeyboardEvent, memo, useCallback } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { isDefined, preprocessFormAmountValue } from "@web/utils";

import { BasketExtraItemQuantityFieldPath, LocalBasketForm } from "src/models";
import { LocalProductService } from "src/services/LocalProductService";

import { ProductTileAmountField } from "../../ProductTileAmountField";

type Props = {
  initialQuantity: number | null | undefined;
  quantityFieldPath: BasketExtraItemQuantityFieldPath;
};

export const QuantityField = memo(({ initialQuantity, quantityFieldPath }: Props) => {
  const { control, setValue } = useFormContext<LocalBasketForm>();

  // Before submission, the model value can be a string, so let's reflect that knowledge in the coerced type
  const modifiedQuantity = useWatch({
    control,
    name: quantityFieldPath,
    defaultValue: initialQuantity,
  }) as string | number | undefined;

  const calculateNewQuantity = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) =>
      LocalProductService.getNewExtraItemQuantityFromKeyboardEvent(
        preprocessFormAmountValue(modifiedQuantity) || LocalProductService.EXTRA_ITEM_MIN_QUANTITY,
        event
      ),
    [modifiedQuantity]
  );

  const setFieldValue = useCallback(
    (newQuantity: number | undefined) => {
      setValue(quantityFieldPath, isDefined(newQuantity) ? newQuantity : null);
    },
    [quantityFieldPath, setValue]
  );

  return (
    <Controller
      name={quantityFieldPath}
      control={control}
      render={({ field }) => (
        <ProductTileAmountField
          {...field}
          className="text-center"
          aria-label="Item Quantity form field (required)"
          calculateNewValue={calculateNewQuantity}
          setNewValue={setFieldValue}
          placeholder="Quantity"
          minValue={LocalProductService.EXTRA_ITEM_MIN_QUANTITY}
          maxValue={LocalProductService.EXTRA_ITEM_MAX_QUANTITY}
        />
      )}
    />
  );
});
QuantityField.displayName = "QuantityField";
