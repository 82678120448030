/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { LiteDraftRequest } from "../models/LiteDraftRequest";
import type { LiteOrderRequisition } from "../models/LiteOrderRequisition";
import type { Unit } from "../models/Unit";

export class LiteDraftsControllerService {
  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static update(
    {
      draftId,
      requestBody,
    }: {
      draftId: string;
      requestBody: LiteDraftRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/lite/drafts/{draftId}",
      path: {
        draftId: draftId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns Unit OK
   * @throws ApiError
   */
  public static delete(
    {
      draftId,
    }: {
      draftId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Unit> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/lite/drafts/{draftId}",
      path: {
        draftId: draftId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteOrderRequisition OK
   * @throws ApiError
   */
  public static create(
    {
      requestBody,
    }: {
      requestBody: LiteDraftRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/drafts",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
