import { z } from "zod";

import { LiteAgentInformationSchema } from "./LiteAgentInformationSchema";

// @deprecated
export const LitePortConfigurationSchema = z.object({
  locationCode: z.string(),
  dutyFree: z.boolean(),
  freeDelivery: z.boolean(),
  agentInformation: LiteAgentInformationSchema.optional(),
});
