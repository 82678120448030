import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  OrderReqService,
  OrderRequisition,
  OrderRequisitionType,
  getBadgeSettingsFromOrderType,
  shouldRenderOrderType,
  shouldRenderOrderTypes,
} from "@web/common";
import {
  Badge,
  DropdownItem,
  IRow,
  RegularDropdownItem,
  Table,
  renderLongStringWithOptionalTooltip,
} from "@web/ui";
import { formatDate, formatShortString, formatTime } from "@web/utils";

import { useAppStateContext } from "src/contexts/AppStateContext";

import { getBadgeSettingsFromOrderStatus } from "./utils";

interface Props {
  orders: Array<OrderRequisition>;
  getUrl: (order: OrderRequisition) => string;
  onDeleteDraft: (draftId: string) => void;
}

export const OrdersTableUI = (props: Props) => {
  const { orders = [], getUrl, onDeleteDraft } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appState] = useAppStateContext();
  const orderTypes = appState.configuration?.orderTypes;

  const getRowDropdownOptions = (
    orderType: OrderRequisitionType,
    orderId: string
  ): DropdownItem[] | undefined =>
    orderType === "DRAFT"
      ? [
          {
            key: "deleteDraft",
            renderComponent: () => (
              <RegularDropdownItem
                label="Delete draft"
                variant="danger"
                onClick={() => {
                  onDeleteDraft(orderId);
                }}
              />
            ),
          },
        ]
      : undefined;

  const canRenderOrderTypeColumn = shouldRenderOrderTypes(orderTypes);
  const isOrderNameEnabled = useFlag("order-name");

  return (
    <Table
      testId="ordersTable"
      headings={[
        {
          text: "Status",
        },
        isOrderNameEnabled
          ? {
              text: "Order Name",
            }
          : undefined,
        {
          text: "PO Reference",
        },
        {
          text: "Supplier",
        },
        {
          text: "Delivery Date",
        },
        {
          text: "Delivery Port",
        },
        canRenderOrderTypeColumn
          ? {
              text: "Order Type",
            }
          : undefined,
        {
          text: "Total Value",
          textEnd: true,
        },
        {
          text: "Currency",
        },
      ]}
      emptyState={{
        title: "No orders yet",
        subtitle: "All your orders once sent, will end up here",
      }}
      rows={orders.map(
        (order: OrderRequisition): IRow => ({
          hasAttachment:
            !!order.attachments?.length ||
            !!order.items.find((item) => item.skuDetails.attachments.length),
          onClick: () => navigate(getUrl(order)),
          dropdownOptions: getRowDropdownOptions(order.type, order.id),
          data: [
            {
              type: "statusBadge",
              data: (
                <Badge
                  withBullet
                  text={getBadgeSettingsFromOrderStatus(order.status, t).text}
                  color={getBadgeSettingsFromOrderStatus(order.status, t).color}
                  size="s"
                  contrast="low"
                />
              ),
            },
            isOrderNameEnabled
              ? {
                  type: "title",
                  data: renderLongStringWithOptionalTooltip(
                    order.subject || "",
                    24,
                    formatShortString
                  ),
                }
              : undefined,
            {
              type: "title",
              data: order.customerOrderId || "-",
            },
            {
              type: "title",
              data: order.supplierInformation?.name || "",
            },
            {
              type: "twoLines",
              data: {
                firstLine: order.deliveryDate ? formatDate(order.deliveryDate) : "-",
                secondLine: order.deliveryDate ? formatTime(order.deliveryDate) : "",
              },
            },
            {
              type: "twoLines",
              data: {
                firstLine: order.port.name,
                secondLine: order.port.locationCode,
              },
            },
            canRenderOrderTypeColumn
              ? {
                  type: "statusBadge",
                  data: shouldRenderOrderType(orderTypes, order.orderType) ? (
                    <Badge
                      {...getBadgeSettingsFromOrderType({
                        orderType: order.orderType,
                        orderTypes,
                      })}
                      size="s"
                    />
                  ) : (
                    <></>
                  ),
                }
              : undefined,
            {
              type: "numeric",
              data: OrderReqService.getTotalGrossAmount(order, true), // true for ommiting currency
            },
            {
              type: "title",
              data: order.totalGrossAmount.currencyCode,
            },
          ],
        })
      )}
    />
  );
};
