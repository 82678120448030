import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

import { ActionBar, Heading, Label, Paragraph, RfqBadge, Tooltip } from "@web/ui";

import { PlacedOrdersSummary } from "src/components/PlacedOrdersSummary";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import trackingCategories from "src/utils/trackingCategories";
import trackingEvents from "src/utils/trackingEvents";

export const RequisitionSuccess = () => {
  const [{ lastCreatedOrderRequisitions, configuration }] = useAppStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!lastCreatedOrderRequisitions) {
      navigate(RoutesConfig.order.overview);
    }
  }, [lastCreatedOrderRequisitions, navigate]);

  if (!lastCreatedOrderRequisitions) {
    return null;
  }

  const isAutoApprovedRequisition = !!configuration?.isAutoApprovedRequisition;
  const catalogRequisitions = lastCreatedOrderRequisitions.filter((or) => !or.isRfqRequisition);
  const requestedRequisitions = lastCreatedOrderRequisitions.filter((or) => or.isRfqRequisition);

  const createNewOrder = () => {
    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.CREATING_NEW_ORDER,
    });
    navigate(RoutesConfig.gatherSetup);
  };

  return (
    <div className="flex flex-col">
      <TopBarController />
      <ActionBar
        primaryButtonSettings={{
          title: "Go to Order Overview",
          onClick: () => navigate(RoutesConfig.order.overview),
          testId: "goToOrderOverviewButton",
        }}
        helperButtons={[
          {
            title: isAutoApprovedRequisition
              ? "Create Another Order"
              : "Create Another Requisition",
            onClick: () => createNewOrder(),
          },
        ]}
      />

      <div className="max-w-2xl mx-auto px-4 mt-3 text-center">
        <Label size="100" className="uppercase" color="text-primaryDefault">
          {isAutoApprovedRequisition ? "Order Confirmation" : "Request Confirmation"}
        </Label>
        <Heading size="100" className="mt-2">
          {isAutoApprovedRequisition
            ? "Thank you for submitting your order"
            : "Request Submitted for Approval"}
        </Heading>
        <Paragraph size="200" className="mt-4">
          {isAutoApprovedRequisition ? (
            <>
              The order has been submitted to the supplier.
              <br />
              Go to the Order Overview to check on their approval.
            </>
          ) : (
            <>
              Your purchase officer will receive your request.
              <br />
              Go to the Order Overview to check on their approval.
            </>
          )}
        </Paragraph>
      </div>

      <div className="mb-12">
        <PlacedOrdersSummary className="mt-6" orders={catalogRequisitions} />

        {requestedRequisitions.length > 0 && (
          <div className="w-full px-4 sm:w-[28rem] sm:px-0 mx-auto mt-6">
            {requestedRequisitions.map((requisition) => {
              const nrRfqItems = requisition.rfqItems?.length || 0;
              return (
                <div
                  className="bg-neutral_100 px-4 py-3 border-neutral_300 rounded-md border-1 mt-5"
                  key={requisition.id}
                >
                  <div>
                    <Label size="200" className="text-textIcon-blackSecondary">
                      Requested Items
                    </Label>
                  </div>
                  <div className="pt-1 flex justify-between items-center">
                    <Paragraph size="200" data-testid="rfqCount">
                      {nrRfqItems} {`Item${nrRfqItems === 0 || nrRfqItems > 1 ? "s" : ""}`}
                    </Paragraph>
                    <Tooltip tooltipText="This item will need to be requested for a quote">
                      <RfqBadge className="cursor-default" />
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
