import { z } from "zod";

import { LitePortConfigSchema } from "./LitePortConfigSchema";
import { OrderStocktakeTypeSchema } from "./OrderStocktakeTypeSchema";

export const LiteOrderTypeConfigurationSchema = z.object({
  name: z.string(),
  description: z.string(),
  type: OrderStocktakeTypeSchema,
  ports: z.array(LitePortConfigSchema),
});
