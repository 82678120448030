import { XIcon } from "@heroicons/react/outline";

import { Heading, Paragraph, RegularButton } from "@web/ui";

type Props = {
  onClose: () => void;
};

export const NetworkConnectionRestoredModal = ({ onClose }: Props) => {
  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      data-testid="networkConnectionRestoredModal"
    >
      <div className="flex items-center justify-between gap-2">
        <Heading size="200" className="flex-1">
          Connection restored
        </Heading>
        <button
          type="button"
          className="flex-none self-start bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-5">
        <Paragraph size="100" color="text-textIcon-blackSecondary">
          All services are now available.
        </Paragraph>
        <Paragraph className="mt-1" size="100" color="text-textIcon-blackSecondary">
          You can send your order, search the catalog or share your drafts.
        </Paragraph>
      </div>
      <div className="mt-5 flex flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          className="ml-2"
          label="Understood"
          onClick={onClose}
        />
      </div>
    </div>
  );
};
