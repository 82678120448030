import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Heading, Label, RegularButton } from "@web/ui";

type Props = {
  productInline?: React.ReactNode;
  homeRoute: string;
};

export const EmptyBasketUI: React.FC<Props> = ({ productInline, homeRoute }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="text-center py-8 flex flex-col items-center w-full">
      <Heading size="200" className="w-96" data-testid="emptyBasket_header">
        {t("common.views.basket.empty.title")}
      </Heading>
      <div className="my-4 w-96">
        <Label size="100" color="text-textIcon-blackSecondary">
          {t("common.views.basket.empty.copy")}
        </Label>
      </div>
      <div className="mt-6 w-20">
        <RegularButton
          variant="primary"
          size="large"
          label={t("common.views.basket.empty.cta")}
          onClick={() => {
            navigate(homeRoute);
          }}
        />
      </div>
      {productInline}
    </div>
  );
};
