import * as Sentry from "@sentry/browser";
import { Component, PropsWithChildren } from "react";

import { CustomerSupportWidget, FallbackErrorPage } from "@web/ui";

type Props = {
  pathname: string;
};

type State = {
  hasError: boolean;
  error: unknown;
};

export class RuntimeErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  state: State = { hasError: false, error: null };

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error };
  }

  componentDidCatch(error: unknown) {
    Sentry.captureException(error);
  }

  componentDidUpdate(prevProps: Readonly<PropsWithChildren<Props>>) {
    if (prevProps.pathname !== this.props.pathname) {
      // Reset state whenever user navigates to other route
      this.setState({ hasError: false, error: null });
    }
  }

  render() {
    const error = this.state.error;

    if (error) {
      console.error(error);
    }

    if (error) {
      // We are handling runtime errors here
      return (
        <>
          <FallbackErrorPage errorDescription="Runtime Error" error={error} />
          <CustomerSupportWidget />
        </>
      );
    }

    return this.props.children;
  }
}
