import { z } from "zod";

import { assertTypeExtensibility } from "@web/utils";

import { OrderStocktakeType, OrderStocktakeTypeSchema } from "src/models";
import { LiteOfflineCategory } from "src/typegens";

import { LiteProductSchema } from "./LiteProductSchema";

const BaseLiteOfflineCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  products: z.array(LiteProductSchema),
  productsNumber: z.number(),
  categoryPortId: z.string(),
  categoryId: z.string(),
  portId: z.string(),
  orderType: OrderStocktakeTypeSchema,
});

const BaseLiteOfflineCategorySchemaReduced = z.object({
  id: z.string(),
  name: z.string(),
  products: z.array(LiteProductSchema),
  productsNumber: z.number(),
});

export const LiteOfflineCategorySchema = BaseLiteOfflineCategorySchema.extend({
  children: z.lazy(() => BaseLiteOfflineCategorySchemaReduced.array()),
});

type LocalLiteOfflineCategoryReduced = z.infer<typeof BaseLiteOfflineCategorySchemaReduced> & {
  children: LocalLiteOfflineCategoryReduced[];
};

export type LocalLiteOfflineCategory = z.infer<typeof LiteOfflineCategorySchema> & {
  children: LocalLiteOfflineCategoryReduced[];
};

export type LocalOfflineCatalogByPort = {
  items: LocalLiteOfflineCategoryReduced[];
  portId: string;
  updatedAt: string;
  orderType: OrderStocktakeType;
};

assertTypeExtensibility<LiteOfflineCategory, LocalLiteOfflineCategory>();
