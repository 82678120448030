import { z } from "zod";

export const AttachmentCategoriesSchema = z.union([
  z.literal("MD"),
  z.literal("SDOC"),
  z.literal("CERT"),
  z.literal("SDS"),
  z.literal("DWG"),
  z.literal("PHOTO"),
  z.literal("SOW"),
  z.literal("SVC_NOTES"),
  z.literal("OTHER"),
  z.literal("UNKNOWN"),
]);
