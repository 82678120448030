import { z } from "zod";

import { OrderStatusSchema } from "./OrderStatusSchema";

export const LiteOrderRequisitionHistorySchema = z.object({
  name: z.string(),
  email: z.string(),
  date: z.string(),
  status: OrderStatusSchema,
  note: z.string().optional(),
});
