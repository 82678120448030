import { z } from "zod";

import { OrderStocktakeTypeSchema } from "./OrderStocktakeTypeSchema";

// @deprecated
export const LiteCatalogConfigurationSchema = z.object({
  name: z.string(),
  description: z.string(),
  type: OrderStocktakeTypeSchema,
});
