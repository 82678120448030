import { z } from "zod";

import { ApiMoneySchema } from "./ApiMoneySchema";

export const LitePriceModifierInformationSchema = z.object({
  id: z.string(),
  name: z.string(),
  amount: ApiMoneySchema,
  changeType: z.union([z.literal("DISCOUNT"), z.literal("ADDITIONAL")]),
});
