import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { useMemo } from "react";

import { OrderType } from "@web/common";
import { useSearchInput } from "@web/common/hooks/useSearchHelpers";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { CatalogSearchService, type LiteCategorySearchResult } from "src/typegens";

import { usePunchOutIntegration } from "./usePunchOutIntegration";

const CATEGORIES_SEARCH_QUERY_KEY_BASE = "categoriesSearch";

type GetQueryKeyArgs = {
  query: string;
  portId: string;
  supplierId: string;
  orderType: OrderType | undefined;
};

const getQueryKey = ({ query, portId, supplierId, orderType }: GetQueryKeyArgs) => [
  CATEGORIES_SEARCH_QUERY_KEY_BASE,
  query,
  portId,
  supplierId,
  orderType,
];

type UseCategoriesSearchQuery = UseQueryResult<LiteCategorySearchResult[]>;

export const useCategoriesSearchQuery = (): UseCategoriesSearchQuery => {
  const [{ port, orderType }] = useAppStateContext();
  const { query } = useSearchInput();
  const { supplierId } = usePunchOutIntegration();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();
  const hasImpaCategorySearchFeature = useFlag("impa-category-search");

  const portId = port?.id || "";
  // supplierId is allowed to be empty
  const defaultedSupplierId = supplierId || "";

  const queryKey = useMemo(
    () =>
      getQueryKey({
        query,
        portId,
        supplierId: defaultedSupplierId,
        orderType,
      }),
    [query, portId, defaultedSupplierId, orderType]
  );

  return useQuery<LiteCategorySearchResult[]>({
    queryKey,
    queryFn: ({ signal }) => {
      return CatalogSearchService.searchCategories(
        {
          portId,
          query,
          supplierId: defaultedSupplierId,
          orderType,
        },
        { signal }
      );
    },
    enabled:
      hasImpaCategorySearchFeature &&
      !!portId &&
      !!orderType &&
      (areOfflineCapabilitiesEnabled ? isOnline : true),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
  });
};
