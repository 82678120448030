import { z } from "zod";

import { ConfigurationPermissionsSchema } from "./ConfigurationPermissionsSchema";
import { LiteFleetAllowSchema } from "./LiteFleetAllowSchema";

export const LiteFleetSchema = z.object({
  id: z.string(),
  name: z.string(),
  permissions: z.array(ConfigurationPermissionsSchema),
  allow: LiteFleetAllowSchema,
});
