import { forwardRef, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { BasketExtraItemRefProductCodeFieldPath, LocalBasketForm } from "src/models";

import { ProductTileTextInput } from "../../ProductTileTextInput";
import { useFormFieldKeyboardEvents } from "../../hooks";

type Props = {
  refProductCodeFieldPath: BasketExtraItemRefProductCodeFieldPath;
};

export const RefProductCodeField = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      { refProductCodeFieldPath },
      // We need to catch `ref`, so we don't get errors in runtime

      ref
    ) => {
      const { control } = useFormContext<LocalBasketForm>();
      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLInputElement>();

      return (
        <Controller
          name={refProductCodeFieldPath}
          control={control}
          render={({ field }) => (
            <ProductTileTextInput
              {...field}
              ref={ref}
              className="px-2"
              placeholder="Product code"
              maxLength={20}
              onKeyDown={handleKeyDown}
              aria-label="Ref Code form field (optional)"
            />
          )}
        />
      );
    }
  )
);
RefProductCodeField.displayName = "RefProductCodeField";
