import { z } from "zod";

import { ApiMoneySchema } from "./ApiMoneySchema";

export const LitePunchoutUnavailableItemSchema = z.object({
  itemId: z.string(),
  name: z.string(),
  displayUnit: z.string(),
  unitPrice: ApiMoneySchema,
  quantity: z.number(),
  supplierItemId: z.string(),
  impaCode: z.string().optional(),
});

export type LocalLitePunchoutUnavailableItem = z.infer<typeof LitePunchoutUnavailableItemSchema>;
