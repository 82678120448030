import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteOrderExtraItem } from "src/typegens";

export const LiteOrderExtraItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  quantity: z.number(),
  measurementUnit: z.string(),
  refProductCode: z.string().optional(),
  refUrl: z.string().optional(),
});

export type LocalLiteOrderExtraItem = z.infer<typeof LiteOrderExtraItemSchema>;

assertTypeEquality<LocalLiteOrderExtraItem, LiteOrderExtraItem, LocalLiteOrderExtraItem>();
