import { useEffect } from "react";

import { SuccessModal } from "@web/common/components/modals/SuccessModal";
import { useModalContext } from "@web/common/contexts/ModalContext";
import { OrderRequisition } from "@web/common/network/model";

import { useAddProductsToBasket } from "../../hooks/useAddProductsToBasket";
import useBasket from "../../hooks/useBasket";
import { LiteProductSku } from "../../typegens";

interface Props {
  draft: OrderRequisition;
  liteProductList: LiteProductSku[];
}

// in ms
const MODAL_VISIBILITY_SPAN = 1000;

export const AllItemsAvailableModal = ({ draft, liteProductList }: Props) => {
  const { setDraft } = useBasket();
  const { closeModal } = useModalContext();
  const { addProductsToBasket } = useAddProductsToBasket({
    catalogItems: draft.items,
    deliveryDate: draft.deliveryDate,
    dutyFreeDeclaration: draft.dutyFreeDeclaration,
    port: draft.port,
    rfqItems: draft.rfqItems,
    orderType: draft.orderType,
    subject: draft.subject,
    extraItems: draft.extraItems,
    supplierInformation: draft.supplierInformation,
  });

  useEffect(() => {
    setTimeout(() => {
      closeModal();
      addProductsToBasket(liteProductList);
      setDraft({ draftId: draft.id, updatedAt: draft.updatedAt.toISOString() });
    }, MODAL_VISIBILITY_SPAN);
    // We want to run this effect only once. This modal is more like a notification,
    // it should automatically disappear after 1s.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SuccessModal message="All draft items available" closeModal={closeModal} />;
};
