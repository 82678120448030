import { Port } from "@web/models";
import { formatDate } from "@web/utils";

export const getDefaultOrderName = (selectedPort?: Port, deliveryDate: string = "") => {
  let portInformation = "";

  if (selectedPort) {
    portInformation = [selectedPort.locationCode, selectedPort.name]
      .filter(Boolean)
      .join(" ")
      .trim();
  } else {
    portInformation = "Untitled";
  }

  const formattedDate = formatDate(deliveryDate);

  return `${portInformation}${formattedDate ? ` - ${formattedDate}` : ""}`;
};
