import { z } from "zod";

import { AttachmentCategoriesSchema } from "./AttachmentCategoriesSchema";

export const LiteAttachmentSchema = z.object({
  name: z.string(),
  attachmentId: z.string(),
  category: AttachmentCategoriesSchema.optional(),
  uploadDate: z.string(),
  uploadedBy: z.string(),
  nameUploadedBy: z.string(),
  size: z.number(),
});
