import { DotsVerticalIcon } from "@heroicons/react/outline";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import classnames from "classnames";

import { ComboButton, Heading, IconButton, RegularButton, Tooltip } from "../../atoms";
import { containerPadding } from "../../organisms";
import { Dropdown, DropdownItem } from "../Dropdown";

export type ActionBarHelperButtonSettings = {
  onClick: () => void;
  title: string;
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
  testId?: string;
};

export type ActionBarPrimaryButtonSettings = {
  title: string;
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltipText?: string;
  testId?: string;
} & (
  | {
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      type?: "button";
    }
  | {
      onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      type: "submit" | "reset";
    }
);

export type ActionBarDropdownSettings = {
  items: DropdownItem[];
};

export type ActionBarBackButtonSettings = {
  onClick: () => void;
  title: string;
};

export type ActionBarProps = {
  leftTitle?: string;
  backButtonSettings?: ActionBarBackButtonSettings;
  primaryButtonSettings?: ActionBarPrimaryButtonSettings;
  helperButtons?: ActionBarHelperButtonSettings[];
  dropdownSettings?: ActionBarDropdownSettings;
};

export const ActionBar = ({
  leftTitle,
  backButtonSettings,
  primaryButtonSettings,
  helperButtons,
  dropdownSettings,
}: ActionBarProps) => {
  const primaryButton = primaryButtonSettings ? (
    <RegularButton
      variant="primary"
      size="large"
      label={primaryButtonSettings.title}
      onClick={primaryButtonSettings.onClick}
      type={primaryButtonSettings.type}
      LeadingIcon={primaryButtonSettings.LeadingIcon}
      TrailingIcon={primaryButtonSettings.TrailingIcon}
      loading={primaryButtonSettings.isLoading}
      disabled={primaryButtonSettings.isDisabled}
      isControlled={true}
      data-testid={primaryButtonSettings.testId || "actionBar_primaryButton"}
    />
  ) : null;

  return (
    <div
      className={classnames(
        containerPadding,
        "w-full relative flex justify-between content-center items-center py-3"
      )}
      data-testid="actionBar"
    >
      {backButtonSettings && (
        <ComboButton
          iconPlacement="leading"
          Icon={ArrowNarrowLeftIcon}
          label={backButtonSettings?.title}
          onClick={backButtonSettings.onClick}
          className="mr-4"
          data-testid="actionBar_backButton"
        />
      )}
      {leftTitle && (
        <Heading size="200" className="text-neutral_900" data-testid="actionBar_leftTitle">
          {leftTitle}
        </Heading>
      )}
      <div className="ml-auto flex flex-row gap-3" data-testid="actionBar_helperButtons">
        {helperButtons &&
          helperButtons.map((btn, index) => (
            <RegularButton
              key={btn.title}
              variant="secondary"
              size="large"
              label={btn.title}
              onClick={btn.onClick}
              loading={btn.isLoading}
              LeadingIcon={btn.LeadingIcon}
              TrailingIcon={btn.TrailingIcon}
              data-testid={btn.testId || `actionBar_helperButton_${index}`}
            />
          ))}
        {primaryButtonSettings?.tooltipText ? (
          <Tooltip tooltipText={primaryButtonSettings.tooltipText}>{primaryButton}</Tooltip>
        ) : (
          primaryButton
        )}
        {dropdownSettings && dropdownSettings.items.length > 0 && (
          <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownSettings.items}>
            <IconButton
              size="large"
              variant="secondary"
              shape="square"
              label="More actions"
              Icon={DotsVerticalIcon}
              data-testid="actionBar_dropdownMenu_trigger"
              isControlled
            />
          </Dropdown>
        )}
      </div>
    </div>
  );
};
