import { z } from "zod";

export const LiteRfqItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  quantity: z.number(),
  measurementUnit: z.string(),
  description: z.string().optional(),
  exampleUrl: z.string().optional(),
});
