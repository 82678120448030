/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ApiListLiteCategory } from "../models/ApiListLiteCategory";
import type { LiteConfiguration } from "../models/LiteConfiguration";

export class HomeService {
  /**
   * @returns ApiListLiteCategory OK
   * @throws ApiError
   */
  public static getConfigurationCategories(
    {
      portId,
      supplierId,
      orderType,
    }: {
      portId: string;
      supplierId?: string;
      orderType?: "BONDED" | "PROVISIONS" | "STORES" | "WELFARE" | "DEFAULT";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListLiteCategory> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/configuration/categories",
      query: {
        portId: portId,
        supplierId: supplierId,
        orderType: orderType,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteConfiguration OK
   * @throws ApiError
   */
  public static index(
    {
      cursor,
      punchoutSessionId,
    }: {
      cursor?: string;
      punchoutSessionId?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteConfiguration> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/configuration",
      query: {
        cursor: cursor,
        punchoutSessionId: punchoutSessionId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
