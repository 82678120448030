import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useNavigate } from "react-router-dom";

import { useModalContext } from "@web/common/contexts";
import { OrderRequisition } from "@web/common/network/model";
import { RegularButton } from "@web/ui";

import { RoutesConfig } from "../../../config/routes";
import { useAppStateContext } from "../../../contexts/AppStateContext";
import { useAddProductsToBasket } from "../../../hooks/useAddProductsToBasket";
import useBasket from "../../../hooks/useBasket";
import { LiteProductSku } from "../../../typegens";
import { ItemsAvailabilityModalContainer } from "../ItemsAvailabilityModalContainer";
import { ProductDiffList } from "../ProductDiffList";

type Props = {
  order: OrderRequisition;
  availableItemsQuery: UseQueryResult<LiteProductSku[]>;
  isDownloadingOfflineCatalog?: boolean;
};

export const ReorderModal = ({
  order,
  availableItemsQuery,
  isDownloadingOfflineCatalog = false,
}: Props) => {
  const { closeModal } = useModalContext();
  const [, dispatch] = useAppStateContext();
  const { clearBasket } = useBasket();
  const navigate = useNavigate();
  const { addProductsToBasket } = useAddProductsToBasket({
    catalogItems: order.items,
    rfqItems: order.rfqItems,
    extraItems: order.extraItems,
    port: order.port,
    // Duty Free needs to be passed, because the redirection is to Basket and not Gather Experience,
    // and Order Summary Form does not have Duty Free fields, where the user could set it.
    dutyFreeDeclaration: order.dutyFreeDeclaration,
    orderType: order.orderType,
    subject: `Reorder: ${order.subject}`,
    supplierInformation: order.supplierInformation,
  });

  const handleCreateNewOrder = () => {
    if (availableItemsQuery.isSuccess) {
      clearBasket();
      dispatch({ type: "setOrderType", value: order.orderType });
      dispatch({ type: "setPort", value: order.port });
      dispatch({ type: "clearDeliveryDate" });
      dispatch({ type: "clearDutyFreeDeclaration" });
      dispatch({ type: "clearOrderName" });
      dispatch({ type: "forcePreconfigureOrderSetup", value: true });
      navigate(RoutesConfig.gatherSetup);
      closeModal();
    }
  };

  const handleAddToBasket = () => {
    if (availableItemsQuery.isSuccess) {
      addProductsToBasket(availableItemsQuery.data);
      closeModal();
    }
  };

  const isPending = availableItemsQuery.isPending || availableItemsQuery.isFetching;
  const error = availableItemsQuery.isError
    ? "There was a problem checking availability of your items. Please try again."
    : undefined;
  const isBlocked = isPending || !!error;
  const ratio: [number, number] | undefined = availableItemsQuery.isSuccess
    ? [availableItemsQuery.data.length, order.items.length]
    : undefined;

  return (
    <ItemsAvailabilityModalContainer
      closeModal={closeModal}
      ctaButtons={
        <>
          <RegularButton
            className="ml-auto mr-5"
            variant="secondary"
            size="large"
            label="Cancel"
            onClick={() => closeModal()}
          />
          <RegularButton
            variant="primary"
            size="large"
            label={
              isBlocked || ratio?.[0] === 0
                ? "Create a New Order"
                : `Add ${ratio?.[0]} Item${ratio && ratio?.[0] > 1 ? "s" : ""} to your basket`
            }
            disabled={isBlocked}
            onClick={() => (ratio?.[0] === 0 ? handleCreateNewOrder() : handleAddToBasket())}
            data-testid="reorder-modal-cta"
          />
        </>
      }
      order={order}
      loading={isPending}
      isDownloadingOfflineCatalog={isDownloadingOfflineCatalog}
      error={error}
      ratio={ratio}
      title="Reorder Items"
    >
      {availableItemsQuery.isSuccess ? (
        <ProductDiffList sourceProducts={order.items} productVariants={availableItemsQuery.data} />
      ) : undefined}
    </ItemsAvailabilityModalContainer>
  );
};
