import { emulateTab } from "emulate-tab";
import { KeyboardEvent, useCallback } from "react";

type UseFormFieldKeyboardEventsParams<T> = {
  calculateNewValue?: (event: KeyboardEvent<T>) => number;
  setNewValue?: (newValue: number | undefined) => void;
};

type UseFormFieldKeyboardEvents<T> = {
  handleKeyDown: (event: KeyboardEvent<T>) => void;
};

export function useFormFieldKeyboardEvents<T>({
  calculateNewValue,
  setNewValue,
}: UseFormFieldKeyboardEventsParams<T> = {}): UseFormFieldKeyboardEvents<T> {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<T>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        emulateTab();
      }

      if (event.key === "ArrowUp") {
        emulateTab.backwards();
      }
      if (event.key === "ArrowDown") {
        emulateTab();
      }

      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        // Prevent accidentally navigating through browser's history while trying to change
        // value; since draft autosave uses debounce, quick navigation after previous
        // value change can lead to a situation where the new value is not persisted
        if (event.altKey) {
          event.preventDefault();
        }

        if (!!calculateNewValue && !!setNewValue) {
          if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
            event.preventDefault();
          }

          const newValue = calculateNewValue(event);
          setNewValue(newValue);
        }
      }
    },
    [calculateNewValue, setNewValue]
  );

  return {
    handleKeyDown,
  };
}
