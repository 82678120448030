import { z } from "zod";

import { ApiMoneySchema } from "./ApiMoneySchema";
import { LiteProductSkuSchema } from "./LiteProductSkuSchema";

export const LitePunchoutAvailableItemSchema = z.object({
  name: z.string(),
  quantity: z.number(),
  unitPrice: ApiMoneySchema,
  details: LiteProductSkuSchema,
});

export type LocalLitePunchoutAvailableItem = z.infer<typeof LitePunchoutAvailableItemSchema>;
