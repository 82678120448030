import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useCallback, useMemo } from "react";

import { addToQueryCache, removeFromQueryCacheByKey, updateInQueryCacheByKey } from "@web/utils";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { LocalOrderDraft } from "src/models";
import { OrderDraftsService } from "src/services/OrderDraftsService";

/**
 * TODO #5641: For whatever reason, using a hook containing a useQuery statement,
 * or using it directly in both the parent and a child component, causes the query
 * to be reinitialized in a loop. Splitting the hook to useDraftsQuery
 * and useDraftsQueryHelpers fixes this issue for now in order to not have
 * to refactor the OrderDetails component, but this should be probably merged
 * back together when OrderDetails is being refactored after the DSHeader
 * flag is removed, and the offline draft is enabled for everyone
 * and the offlineDraft configuration is removed along old code.
 */

export const ORDER_DRAFTS_QUERY_KEY_BASE = "orderDrafts";

const getQueryKey = (vesselId: string, areOrderExtraItemsEnabled: boolean) => [
  ORDER_DRAFTS_QUERY_KEY_BASE,
  vesselId,
  areOrderExtraItemsEnabled,
];

// Sort by `updatedAt`, descending
// Since all dates are in ISO-8601 format without TZ adjustment, we can sort them lexicographically
// and avoid memory allocation in the sorting method
const getSortedDrafts = (drafts: LocalOrderDraft[]) =>
  [...drafts].sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : a.updatedAt < b.updatedAt ? 1 : 0));

type GetValidatedDraftArgs = {
  draft: LocalOrderDraft;
  areOrderExtraItemsEnabled: boolean;
};

const getValidatedDraft = ({
  draft,
  areOrderExtraItemsEnabled,
}: GetValidatedDraftArgs): LocalOrderDraft => ({
  ...draft,
  extraItems: areOrderExtraItemsEnabled ? draft.extraItems : [],
});

export const useOrderDraftsQuery = (): UseQueryResult<LocalOrderDraft[]> => {
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const [{ configuration }] = useAppStateContext();
  const vesselId = configuration?.vessel.id || "";
  const areOrderExtraItemsEnabled = !!configuration?.fleet.allow.orderExtraItems;
  const queryKey = useMemo(
    () => getQueryKey(vesselId, areOrderExtraItemsEnabled),
    [vesselId, areOrderExtraItemsEnabled]
  );

  return useQuery<LocalOrderDraft[]>({
    queryKey,
    queryFn: async () => {
      if (!isOfflineDraftEnabled || !vesselId) {
        return Promise.resolve([]);
      }

      const drafts = await OrderDraftsService.getDrafts(vesselId);
      return getSortedDrafts(drafts).map((draft) =>
        getValidatedDraft({
          draft,
          areOrderExtraItemsEnabled,
        })
      );
    },
    enabled: !!vesselId,
  });
};

type UseOrderDraftsQueryHelpers = {
  invalidate: () => void;
  getDraftById: (drafts: LocalOrderDraft[], id: string) => LocalOrderDraft | undefined;
  addDraftToQuery: (draft: LocalOrderDraft) => void;
  removeDraftFromQuery: (draftId: string) => void;
  updateDraftInQuery: (draft: LocalOrderDraft) => void;
};

export const useOrderDraftsQueryHelpers = (): UseOrderDraftsQueryHelpers => {
  const queryClient = useQueryClient();
  const [{ configuration }] = useAppStateContext();
  const vesselId = configuration?.vessel.id || "";
  const areOrderExtraItemsEnabled = !!configuration?.fleet.allow.orderExtraItems;
  const queryKey = useMemo(
    () => getQueryKey(vesselId, areOrderExtraItemsEnabled),
    [vesselId, areOrderExtraItemsEnabled]
  );

  const removeDraftFromQuery = useCallback(
    (draftToRemoveId: string) => {
      queryClient.setQueryData(queryKey, removeFromQueryCacheByKey({ id: draftToRemoveId }, "id"));
    },
    [queryClient, queryKey]
  );

  const updateDraftInQuery = useCallback(
    (draftToUpdate: LocalOrderDraft) => {
      queryClient.setQueryData(queryKey, updateInQueryCacheByKey(draftToUpdate, "id"));
      queryClient.setQueryData(queryKey, (collection?: LocalOrderDraft[]) =>
        collection ? getSortedDrafts(collection) : undefined
      );
    },
    [queryClient, queryKey]
  );

  const addDraftToQuery = useCallback(
    (draftToAdd: LocalOrderDraft) => {
      queryClient.setQueryData(queryKey, addToQueryCache(draftToAdd));
    },
    [queryClient, queryKey]
  );

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  const getDraftById = useCallback(
    (drafts: LocalOrderDraft[], id: string) => drafts.find((draft) => draft.id === id),
    []
  );

  return {
    invalidate,
    getDraftById,
    addDraftToQuery,
    removeDraftFromQuery,
    updateDraftInQuery,
  };
};
